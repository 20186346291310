import { graphql } from 'babel-plugin-relay/macro';

export const StuSubsCreateportalURLMutation = graphql`
  mutation StuSubsCreateportalURLMutation {
    stu_subs_createportalurl {
        portalUrl
        errors
        success
    }
}`;
