import React, { useState, Suspense } from 'react';
import { useFragment } from 'react-relay';
import * as Sentry from '@sentry/react';
import {
  Card, CardContent, Ty, Grid2Ct, Grid2, IcSvgList, IcSvg, Box, styled, WCLThemeProps,
  Dialog, CardSmallColorIcon, Ic,
} from '@languageconvo/wcl';
import { AlignIconText } from '../../../common/components/styling/AlignIconText';
import { SkillsAllFragment, SkillsDetailFragment } from './relay/Skills';
import { SkillsAllFragment$key } from './relay/__generated__/SkillsAllFragment.graphql';
import { SkillsDetailFragment$key } from './relay/__generated__/SkillsDetailFragment.graphql';

interface MainProps {
  fragmentRef: SkillsAllFragment$key
}

export const ProgressHome2 = ({ fragmentRef }: MainProps) => {
  const data = useFragment(SkillsAllFragment, fragmentRef);
  const allLevels = data.skills_levels_connection;

  // #region info dialog

  const [modalStateInfo, setModalStateInfo] = useState<boolean>(false);
  const handleModalOpenInfo = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateInfo(true);
  };
  const handleModalCloseInfo = () => {
    setModalStateInfo(false);
  };

  // #endregion

  // #region individual skill dialogs

  // the modal/open close state
  const [modalState, setModalState] = useState<boolean>(false);
  // the individual skill that was clicked on (display_order field from the db)
  const [skillClicked, setSkillClicked] = useState<number>(0);

  const handleModalClick = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    // get the id of the skill that was clicked on, and set it to our state var
    const skillId = parseInt(event.currentTarget.getAttribute('data-skillid'), 10);
    setSkillClicked(skillId);
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* question box, how this works */}
      <Grid2Ct sx={{ mb: 2 }}>
        {/* question button */}
        <Grid2 xs={12} xl={10} xlOffset={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CardSmallColorIcon
              icon={IcSvgList.question1}
              color="cardstandard"
              hovercursor="pointer"
              onClick={handleModalOpenInfo}
              cp={{ 'data-intercom': 'howprogressworks' }}
            />
          </Box>
        </Grid2>
      </Grid2Ct>

      <Suspense fallback={<span> </span>}>
        <Grid2Ct>
          { allLevels.edges.map(({ node }: any) => (
            <LevelCard fragmentRef={node} handleModalClick={handleModalClick} />
          ))}
        </Grid2Ct>
      </Suspense>

      {/* individual skill dialog */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentGreen1"
      >
        <DialogContentsContainer skill={skillClicked} allLevels={allLevels} />
      </Dialog>

      {/*  */}
      <Dialog
        isOpen={modalStateInfo}
        onClose={handleModalCloseInfo}
        width="md"
        color="accentGreen1"
      >
        <InfoModalContents />
      </Dialog>
    </>
  );
};

// #region components

// #region card for an entire level

interface Props {
  fragmentRef: SkillsDetailFragment$key
  handleModalClick: any
}
const LevelCard = ({ fragmentRef, handleModalClick }: Props) => {
  const levelData = useFragment(SkillsDetailFragment, fragmentRef);

  // #region award icon

  let ic = IcSvgList.award1;
  if (levelData.display_order === 1) {
    ic = IcSvgList.award2;
  } else if (levelData.display_order === 2) {
    ic = IcSvgList.award3;
  } else if (levelData.display_order === 3) {
    ic = IcSvgList.award4;
  } else if (levelData.display_order === 4) {
    ic = IcSvgList.award5;
  } else if (levelData.display_order === 5) {
    ic = IcSvgList.award6;
  } else if (levelData.display_order === 6) {
    ic = IcSvgList.award7;
  } else if (levelData.display_order === 7) {
    ic = IcSvgList.award8;
  } else if (levelData.display_order === 8) {
    ic = IcSvgList.award9;
  } else if (levelData.display_order === 9) {
    ic = IcSvgList.award10;
  } else if (levelData.display_order === 10) {
    ic = IcSvgList.award13;
  } else if (levelData.display_order === 11) {
    ic = IcSvgList.award12;
  } else if (levelData.display_order === 12) {
    ic = IcSvgList.award11;
  } else {
    Sentry.captureException(
      new Error('a level needs an icon'),
      {
        extra: {
          lvl: levelData,
          lvlDisplay: levelData.display_order,
        }
      }
    );
  }

  // #endregion

  return (
    <Grid2 xs={12} xl={10} xlOffset={1} data-testid="progressPage">
      <Card>
        <CardContent>
          <Grid2Ct sx={{ mt: 1 }}>
            <Grid2 xs={12}>
              {/* level title, icon */}
              <Box display="flex" justifyContent="center">
                <AlignIconText>
                  <IcSvg icon={ic} width="24px" height="24px" />&nbsp;&nbsp;&nbsp;
                  <Ty align="center" v="h2New" removeMb>{levelData.title_en}</Ty>
                </AlignIconText>
              </Box>

              {/* level number */}
              <Grid2Ct>
                <Grid2 xs={12}>
                  <Ty removeMb align="center">Level {levelData.display_order}</Ty>
                </Grid2>
              </Grid2Ct>

              {/* each skill */}
              <Grid2Ct sx={{ mt: 3 }}>
                {levelData.skills.map((el) => (
                  <IndividualSkill skill={el} handleModalClick={handleModalClick} />
                ))}
              </Grid2Ct>
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  );
};

// #endregion

// #region individual skill box

const IndividualSkill = ({ skill, handleModalClick }: any) => {
  const skillId = skill.display_order;
  const header = `Skill #${skillId}`;
  const skillName = `${skill.title_en}`;

  return (
    <Grid2 xs={12} md={6} data-testid={`skill-${skillId}`}>
      <Box display="flex" justifyContent="center">
        <IndividualSkillBox onClick={(event) => handleModalClick(event)} data-skillid={skillId}>
          <Ty align="center">{header}</Ty>
          <Ty align="center" removeMb>{skillName}</Ty>
        </IndividualSkillBox>
      </Box>
    </Grid2>
  );
};

const IndividualSkillBox = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '15px 15px 15px 15px',
  cursor: 'pointer',
  width: '400px',
}));

// #endregion

// #region dialog contents

// as a first step for the dialog contents, we map through each level
const DialogContentsContainer = ({ skill, allLevels }: any) => {
  // eslint-disable-next-line
  const x = 1;

  return (
    allLevels.edges.map(({ node }: any) => (
      <DialogContents fragmentRef={node} selectedSkill={skill} />
    ))
  );
};

// here we have to loop through each skill, find the skill that the user clicked on,
// and then we can build the display/output
const DialogContents = ({ fragmentRef, selectedSkill }: any) => {
  const levelData = useFragment(SkillsDetailFragment, fragmentRef);
  let foundSkill = false;
  let fullTitle = '';
  let description = '';
  let examples: string[] = ['one'];
  levelData.skills.forEach((el: any) => {
    if (el.display_order === selectedSkill) {
      foundSkill = true;
      fullTitle = `Skill #${el.display_order}: ${el.title_en}`;
      description = el.description_en;
      try {
        examples = JSON.parse(el.examples_en);
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  });

  if (!foundSkill) {
    return (null);
  }

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty v="h2New" align="center">{fullTitle}</Ty>
      </Grid2>
      <Grid2 xs={12}>
        <Ty>{description}</Ty>
      </Grid2>
      <Grid2 xs={12}>
        <Ty><strong>Examples</strong></Ty>
        {examples.map((el) => (
          <Ty>{el}</Ty>
        ))}
      </Grid2>
    </Grid2Ct>
  );
};

// #endregion

// #region info dialog
const InfoModalContents = () => (
  <Grid2Ct>
    <Grid2 xs={12} sm={10} smOffset={1}>
      {/* page automatically updates */}
      <Ty v="h2New">
        <Ic iconName="language" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;Progress by Learning Skills, Not &quot;Grammar Topics&quot;
      </Ty>
      <Ty>
        We help you approach your language learning a better way: by focusing on skills, on
        real-world abilities. Sure, knowing what &quot;Regular Tense -ER Verbs&quot; means can
        be helpful (and if you&apos;re taking a class in school might be needed) but...can
        you say hello to someone? Can you hold a basic conversation and ask someone about their
        family? Can you read a portion of the newspaper?
        Can you explain what you plan to do next week?
        These are real-world skills that make up your abilities in a language, and that is
        how we have developed our programs.
      </Ty>

      {/* dont worry about class topic */}
      <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
        <Ic iconName="square-info" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;About Levels
      </Ty>
      <Ty>
        We have broken down our list of skills into various levels, and you will notice that each
        level&apos;s title is written to describe what your abilities are when you finish that
        level. Just like each skill is descriptive of the *ability* you are learning, each level is
        descriptive of your *overall ability* once you reach that level.
      </Ty>
      <Ty>
        That said, we try not to put a lot of emphasis on &quot;leveling up&quot;.
        If you are the type
        of learner that loves progressing through a program step by step, great! If not though,
        don&apos;t worry &mdash; we focus on helping you gain abilities, with no pressure to
        constantly &quot;level up&quot;.
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// #endregion

// #endregion
