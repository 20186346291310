import { useCallback, useEffect, useState } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { usePostHog } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { useLocation } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { EventsDataFragment } from './relay/EventsData';

export const useEventsManager = (
  fragmentRefApplevelData: any
) => {
  // TODO: in App.tsx, we're setting window.rudderanlaytics. that's likely a very bad thing to
  // be doing there, because that is an object that rudderstack itself creates. we are
  // potentially overwriting the object that they created
  // @ts-ignore type mismatch for RudderAnalytics
  const [analytics, setAnalytics] = useState<RudderAnalytics>(window.rudderanalytics);
  const location = useLocation();
  const allUserData: any = useFragment(
    EventsDataFragment,
    fragmentRefApplevelData.users_connection.edges[0].node
  );
  const userRandomId = allUserData.random_id_public;
  const userEmail = allUserData.email;
  const userFn = allUserData.first_name;
  const userLn = allUserData.last_name;

  // posthog -- remember it's very important to *not* directly import posthog, instead we
  // always need to usePostHog (outside of the initial posthog init)
  const posthog = usePostHog();

  // create rudderstack analytics object if it does not yet exist
  useEffect(() => {
    if (analytics) {
      setAnalytics(analytics);
    }
  }, [analytics]);

  // called once every time the route changes (see useEffect below). here we implement the
  // "pageview" event of teach tag (intercom, rudderstack, etc.)
  const reportAnalytics = useCallback(() => {
    // #region simple validation of user data

    if (userRandomId.length < 5) {
      Sentry.captureException(
        new Error('reportAnalytics userRandomId has an error'),
        {
          extra: {
            id: userRandomId,
            em: userEmail,
          }
        }
      );
    }
    if (userEmail.length < 1) {
      Sentry.captureException(
        new Error('reportAnalytics userEmail has an error'),
        {
          extra: {
            id: userRandomId,
            em: userEmail,
          }
        }
      );
    }
    if (userFn.length < 1) {
      Sentry.captureException(
        new Error('reportAnalytics userFn has an error'),
        {
          extra: {
            id: userRandomId,
            em: userEmail,
            fn: userFn,
          }
        }
      );
    }
    if (userLn.length < 1) {
      Sentry.captureException(
        new Error('reportAnalytics userLn has an error'),
        {
          extra: {
            id: userRandomId,
            em: userEmail,
            ln: userLn,
          }
        }
      );
    }

    // #endregion

    // #region load and pageview for intercom

    // load *and* pageview for Intercom. we stopped using rudderstack to load intercom for us, there
    // were too many bugs with that integration. using intercom directly is much cleaner. for
    // example, with rudderstack we could not use hide_default_launcher
    try {
      if (process.env.REACT_APP_INTERCOM_APPID === undefined) {
        throw new Error('BIG ISSUE: REACT_APP_INTERCOM_APPID was undefined');
      }

      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APPID,

        // within the app, we don't show the launcher because it gets in the way. for the user to
        // see it they'll need to go to the more/settings page and click to open it.
        // important: we SHOULD do this here, not in the intercom console settings. two reasons:
        //  1. by doing it here, when the user closes a chat thread the intercom icon fully
        //    disappears which is a much better experience than leaving it there
        //  2. the chat icon does not "flash" and show for a moment when the app first loads
        hide_default_launcher: true,

        // note that we *dont* include name or email. main reason being, that will be set
        // by reverse etl process
        user_id: userRandomId,

        // NOTE: vertical padding does NOT work on mobile screen widths
        // https://community.intercom.com/messenger-8/is-there-a-way-to-adjust-the-position-of-the-messenger-on-mobile-devices-3026
        // the workaround is to create custom CSS as noted on that link. which we did,
        // look at our app custom css
        // vertical_padding: 90,
      });

      // log errors to sentry
    } catch (e) {
      Sentry.captureException(e);
    }

    // #endregion

    // #region pageview for facebook

    try {
      // @ts-ignore
      window.fbq('track', 'PageView');
    } catch (e) {
      Sentry.captureException(e);
    }

    // #endregion

    // #region pageview for posthog
    try {
      if (posthog) {
        posthog.capture(
          '$pageview',
          {
            // eslint-disable-next-line quote-props
            '$current_url': window.location.href,
          }
        );

        // posthog should always exist at this point, log if for some reason it does not
      } else {
        Sentry.captureMessage('posthog did not exist during a pageview event');
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    // #endregion

    // #region pageview for rudderstack

    if (analytics) {
      // first, call pageview
      analytics.page();

      // then call identify
      analytics.identify(
        // user id (this is users.random_id_public in our db)
        userRandomId,
        // traits
        {
          email: userEmail,
          firstName: userFn,
          lastName: userLn,
        },
      );
    }

    // #endregion
  }, [analytics, userRandomId, userEmail, userFn, userLn, posthog]);

  // call reportAnalytics only when the actual page path changes. we use location.pathname instead
  // of just location, so that if for example the user is on /practice and they click the Practice 
  // link in the navbar (such that the path doesn't actually change), we do not call reportAnalytics
  useEffect(() => {
    reportAnalytics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { analytics };
};
