import React from 'react';
import * as Sentry from '@sentry/react';
import { BroadcastChannel } from 'broadcast-channel';
import { isJsonString } from './isJSONString';
import { handleLogoutAndRemoveRefreshToken } from '../services/fetchService';
import { sitedata } from './sitedata';
import { sleep } from '../common/utils/sleep';
import { evtLogout } from '../common/utils/eventmanager/eventmanagerNonhook';

const logoutChannel = new BroadcastChannel('logout');

// this function will remove jwt from localStorage and log user out of the
// app, the user will be redirected to login page and has to login again.
export const removeJWTFromLocalStorageAndLogoutUser = async (
  setLogoutInProgress?: any,
  isAnotherTab = false,
) => {
  // const { studentLocalStorageKeys, url } = sitedata;
  const { studentLocalStorageKeys, url } = sitedata;

  // handleLogoutAndRemoveRefreshToken() is probably the most important function to run in this
  // flow, along with forwarding the user to the login page. so if any of these things fail
  // we still want those other things to occur
  try {
    if (setLogoutInProgress) {
      setLogoutInProgress(true);
    }

    // if this logout function was called as a result of the broadcast channel, we sleep a bit
    // so that if the user actually clicked the logout button from *this* current tab, this
    // entire logout code wont run a second time
    if (isAnotherTab) {
      await sleep(1500);
    }

    // first, we delete their jwt from localstorage
    localStorage.removeItem(studentLocalStorageKeys.studentSecurity);

    // call our logout js event functions (intercom, posthog, rudderstack, etc.). note that we
    // do not call our js events if this was a result of a secondary browser tab being open and
    // receiving the broadcast channel notification about the user logging out in another
    // tab. we'll only call the js functions once, from the primary tab the user logged out from
    // so that we don't create multiple "logout" events in our js event tools
    if (!isAnotherTab) {
      await evtLogout();
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  // if this fails, not good, but we still want BroadcastChannel code to run. reason being, that
  // code will fire and again tell this current browser tab to do this logout flow (meaing, it
  // calls this removeJWTFromLocalStorageAndLogoutUser itself). so the effect will be that if
  // handleLogoutAndRemoveRefreshToken in this try fails, we'll still hopefully get a second try
  // at logging the user out via the broad channel notification
  try {
    // then we delete their refresh token and nts-session cookie. this is done by php server, as
    // its an httponly cookie
    await handleLogoutAndRemoveRefreshToken();
  } catch (e) {
    Sentry.captureException(e);
  }

  // notify the user's other open tabs that they should log out. note that we dont call this
  // again if this logout function call itself was already called from the broadchannel event
  // being fired (so that we avoid a cascade of broadcast events firing across many tabs)
  try {
    // IMPORTANT: this MUST come AFTER the evtLogout() function above, which calls our js
    // functions. reason being, when this broadcastchannel thing happens, it will notify
    // other browser tabs but also *this* browser tab, to do the logout process. if the
    // evtLogout() were to be further down in this flow, it may never get called
    if (!isAnotherTab) {
      logoutChannel.postMessage('Logout');
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  // finally we redirect them to the old login page
  // previously, we were using window.location.href  Some stackoverflow suggestions are to use
  //  .replace instead. The reason *might* be that window.location.href is supposed to emulate a
  //  link click, so maybe when it's used it needs to be the first action the browser takes
  window.location.replace(url.app.login);

  // window.location.replace is not aysnc, code after it will run! so we add this to be sure
  // code doesn't keep running. if you remove this, whatever code you have after calling
  // removeJWTFromLocalStorageAndLogoutUser will run, until the broswer redirects. this can
  // cause unexpected issues, because code calling this function expects the app to redirect
  // and then stop other execution
  await sleep(4000);

  // the user should have been sent to the login page by now. if not, that's a big problem
  Sentry.captureMessage('IMPORTANT: removeJWTFromLocalStorageAndLogoutUser failed to redirect the user to login page');
};

/*
  When a user logs out, they should be logged out in all tabs they have open. 
  We've used broadcase channel APIs and listening to all messages, once from any tab, 
  user do logout, we post a logout message which we can receive here in our logoutAllTabs
  this calls the logout again in all opened tabs and close that channel.  */
export const logoutAllTabs = async (
  setLogoutInProgress: React.Dispatch<React.SetStateAction<boolean>>
) => {
  logoutChannel.onmessage = (message) => {
    if (message === 'Logout') {
      // we add the true parameter here because that indicates this logout is occcuring as a result
      // of the user actually clicking logout in a different tab
      removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress, true);
    }
  };
};

/* capturing logout event from legacy app 
    logging user out from ifram if he/she is not login in legacy app (inside iframe)
*/
export const handleLogoutEventFromIframe = (setLogoutInProgress: any) => {
  let data: any;
  window.addEventListener('message', (e: any) => {
    if (typeof e?.data === 'string' && isJsonString(e?.data)) {
      data = JSON.parse(e.data);
      if (data.eventName === 'logout') {
        removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress, false);
      }
    }
  });
};
