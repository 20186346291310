import { graphql } from 'babel-plugin-relay/macro';

export const EventsDataFragment = graphql`
  fragment EventsDataFragment on users {
    random_id_public @required(action: THROW)
    email @required(action: THROW)
    first_name @required(action: THROW)
    last_name @required(action: THROW)
  }
`;
