import React, { useState } from 'react';
import {
  Grid2Ct, Grid2, Ty, Button, Box, IcSvg, IcSvgList, LottiePlayer,
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { ClassroomBasicDataTermsprivacyMutation } from '../../../relay/ClassroomBasicData';
import { setHasInteractedTrue } from '../../../../../../common/relay/clientschema/relayappsettings/groupclassFunctionality';
import Lion from '../../../../../../common/assets/lottie/lion-sitting.json';

// require user accept rules of using group classroom
export const V203 = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commitMutation] = useMutation(
    ClassroomBasicDataTermsprivacyMutation
  );

  const AcceptGroupRules = () => {
    // to disable the button making our state true
    setIsLoading(true);
    /* set relay client schema groupClass.hasInteracted = true when button clicked so that
    the user won't have to click "join class" button after this screen */
    setHasInteractedTrue();

    // make mutation call
    commitMutation({
      variables: { },
      onCompleted() {
        setIsLoading(false);
      },
      onError(err: any) {
        // eslint-disable-next-line
        console.log(err);
      },
    });
  };

  return (
    <>
      {/* lottie, title */}
      <Grid2Ct
        // this vertically centers the children
        sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
      >
        <Grid2 xs={12}>
          <LottiePlayer animationSrc={Lion} animationHeight={200} />
        </Grid2>

        <Grid2 xs={12} sm={10} smOffset={1}>
          <Ty v="h1New" align="center">Class Rules</Ty>
          <Ty>
            Please review these important rules (scroll down to view everything), and accept
            by clicking the button at the end.
          </Ty>
        </Grid2>
      </Grid2Ct>

      {/* text of rules */}
      <Grid2Ct sx={{ mt: 3 }}>
        {/* text */}
        <Grid2 xs={12} sm={10} smOffset={1}>
          <Ty v="h2New"><IcSvg icon={IcSvgList.microphone1} width="24px" height="24px" />&nbsp;&nbsp;Rule #1 - Ensure Correct Mic &amp; Quiet Environment</Ty>
          <Ty>
            Once the class starts, always take a quick look at the
            &quot;Microphone&quot; button near the bottom of the page to ensure that we&apos;ve
            selected the correct one for you.
            When speaking with people online, you&apos;ve probably experienced someone who
            had a bad microphone or loud background noise &mdash;
            it&apos;s not fun!
          </Ty>

          <Ty v="h2New" cp={{ sx: { mt: 4 } }}><IcSvg icon={IcSvgList.cursing1} width="24px" height="24px" />&nbsp;&nbsp;Rule #2 - Be Courteous, &amp; Never Use Profanity</Ty>
          <Ty>
            Use of inappropriate language, being mean or argumentative with others can result
            in being banned from classes. Make this a fun, enjoyable experience
            for everyone!
          </Ty>

          <Ty v="h2New" cp={{ sx: { mt: 4 } }}><IcSvg icon={IcSvgList.earth1} width="24px" height="24px" />&nbsp;&nbsp;Rule #3 - Avoid Politics &amp; Religion</Ty>
          <Ty>
            There are over 4000 religions practiced on our planet, and far more
            political viewpoints; this is not the place to discuss your religious or
            political views. Doing so may result in you being banned.
          </Ty>
        </Grid2>

        {/* button */}
        <Grid2 xs={12} sm={10} smOffset={1} sx={{ mt: 2, mb: 4 }}>
          <Box display="flex" justifyContent="center">
            {/* mutation when user clicks this agree button */}
            <Button color="accentGreen1" onClick={() => AcceptGroupRules()} disabled={isLoading}>I Agree to All Rules</Button>
          </Box>
        </Grid2>
      </Grid2Ct>
    </>
  );
};
