import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardContent, Grid2, Grid2Ct, Card, Ty, CardSmallColor, IcSvg, IcSvgList, Box, Ic,
  Dialog,
} from '@languageconvo/wcl';
import { AlignTitle } from './Discover1Header.style';

export const DiscoverHeader = () => {
  const { t } = useTranslation(['pgLsnDiscover']);

  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      <Grid2Ct>
        <Grid2 xs={12} sm={8} smOffset={2} md={8} mdOffset={2}>
          <Card>
            <CardContent>
              <Grid2Ct>
                {/* title */}
                <Grid2 xs={12}>
                  <AlignTitle>
                    <IcSvg icon={IcSvgList.teacher1} height="40px" width="40px" /><Ty v="h2New" align="center" removeMb>&nbsp;&nbsp;{t('pageH1')}</Ty>
                  </AlignTitle>
                </Grid2>

                {/* text */}
                <Grid2 xs={12} xl={8} xlOffset={2}>
                  <Ty v="p" align="center">Find a great <strong>long-term</strong> teacher that&apos;s a perfect fit just for you! Many teachers offer <strong>completely free</strong> trial lessons.</Ty>
                </Grid2>

                {/* common questions */}
                <Grid2 xs={12}>
                  <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                    <Box sx={{ width: '300px' }}>
                      <CardSmallColor
                        text="Common Questions"
                        icon={IcSvgList.question1}
                        color="accentBlue1"
                        onClick={handleModalOpen}
                        hovercursor="pointer"
                      />
                    </Box>
                  </Box>
                </Grid2>
              </Grid2Ct>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2Ct>

      {/* modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;Will I get the same teacher every lesson?</Ty>
            <Ty>
              Yes! Well, it&apos;s up to you &mdash; you schedule your own lessons. Most students
              stick with one or two teachers, but it&apos;s entirely your choice.
            </Ty>

            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;Can I try new teachers?</Ty>
            <Ty>
              Absolutely! Try a lesson with any teacher at any time. Your current teacher will not
              mind at all, in fact they encourage it.
            </Ty>

            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;Is it free to try a new teacher?</Ty>
            <Ty>
              Most teachers offer free trial lessons, but some do not. For teachers that do not
              currently offer a free lesson, you can purchase a package of the language they teach
              (even a small 2 hour package) and use it to schedule a short 30 minute lesson
              with that teacher. If it turns out that teacher is not a good fit for you, simply
              schedule another lesson with a different teacher.
            </Ty>

            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;How can I view teachers that teach other languages?</Ty>
            <Ty>
              Change the language you have set as the language you&apos;re currently learning. Look
              for the &quot;language flag&quot; and click it to change language.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};
