// import posthog from 'posthog-js';
// import { usePostHog } from 'posthog-js/react';
// import { EventdtPurchase } from './eventmanager';
import * as Sentry from '@sentry/react';
import { EventdtAccountCreated, EventdtPurchase } from './eventmanager';

// IMPORTANT: DO NOT CHANGE THESE
// IMPORTANT: DO NOT CHANGE THESE (unless you REALLY know what you're doing)
// the names/titles of each event. note that some destinations require special naming.
// these are very important in each tool, do NOT change them unless you know you really
// need to
enum EventTitles {
  createaccount = 'account_created',
  purchase = 'order_completed',
}

// TODO: types
// We set type to "any" in these functions, would be better to include the actual type. Posthog
// docs though say "do not directly import posthog apart from installation" so we are not doing
// that here yet. They should hopefully provide a @types definition in future

export const accountcreatedPg = (pg: any, dt: EventdtAccountCreated) => {
  try {
    // note: posthog recommends ? optional chaining, in case posthog has not yet initialized
    pg?.capture(EventTitles.createaccount, {
      eventID: dt.eventuuid,
    });

    // start a session recording
    pg?.startSessionRecording();

    // eslint-disable-next-line no-console
    console.log('pg: account created');
  } catch (e) {
    Sentry.captureException(e);

    // eslint-disable-next-line no-console
    console.log(e);
  }
};

// purchasesd private lessons
export const purchasePg = (pg: any, dt: EventdtPurchase) => {
  try {
    // note: posthog recommends ? optional chaining, in case posthog has not yet initialized
    pg?.capture(EventTitles.purchase, {
      value: dt.value,
      currency: 'USD',
      eventID: dt.eventuuid,
    });

    // eslint-disable-next-line no-console
    console.log('pg: purchase');
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
