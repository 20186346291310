import { graphql } from 'babel-plugin-relay/macro';

export const GetUserRecentActionQuery = graphql`
  query GetUserRecentActionQuery {
    subs_mainupdate_history_connection(
    order_by: {
      ts_user_action: desc
    },
    first: 1
  ) {
    ...GetUserRecentActionFragment
  }
  }
`;

export const GetUserRecentActionFragment = graphql`
  fragment GetUserRecentActionFragment on subs_mainupdate_historyConnection {
    edges {
      node {
          subs_mainplans {
            order
          }
      ts_processed
      }
    }
  }
`;
