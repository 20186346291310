/**
 * @generated SignedSource<<619c424235d078086092f0bcb909fad0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSubsGetprorationamountMutation$variables = {
  newPlanId: number;
};
export type StuSubsGetprorationamountMutation$data = {
  readonly stu_subs_getprorationamount: {
    readonly data: {
      readonly amountDue: string;
    };
    readonly success: boolean;
  } | null;
};
export type StuSubsGetprorationamountMutation = {
  response: StuSubsGetprorationamountMutation$data;
  variables: StuSubsGetprorationamountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newPlanId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newPlanId",
        "variableName": "newPlanId"
      }
    ],
    "concreteType": "StuSubsGetprorationamountOutput",
    "kind": "LinkedField",
    "name": "stu_subs_getprorationamount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StuSubsGetprorationamountOutputData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountDue",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSubsGetprorationamountMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuSubsGetprorationamountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7797fced1480940fa4701dd1a96b1bc8",
    "id": null,
    "metadata": {},
    "name": "StuSubsGetprorationamountMutation",
    "operationKind": "mutation",
    "text": "mutation StuSubsGetprorationamountMutation(\n  $newPlanId: Int!\n) {\n  stu_subs_getprorationamount(newPlanId: $newPlanId) {\n    data {\n      amountDue\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f3f033e02be4dbcb6898cbc05967fdd";

export default node;
