import {
  styled,
} from '@languageconvo/wcl';
import MuxPlayer from '@mux/mux-player-react';

// mux video player styling
export const MuxPlayerCustom = styled(MuxPlayer)(() => ({
  maxHeight: '230px !important',
  width: 'auto',
  aspectRatio: 16 / 9,
}));
