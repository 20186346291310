import { graphql } from 'babel-plugin-relay/macro';

export const HomepageloadQuery = graphql`
  query HomepageloadQuery {
    users_connection {
      edges {
        node {
          ...HomepageloadAcctcreateFragment
        }
      }
    }
  }
`;

// data needed for the "account created" tagmanager event
export const HomepageloadAcctcreateFragment = graphql`
  fragment HomepageloadAcctcreateFragment on users {
    random_id_public @required(action: THROW)
    email @required(action: THROW)
    first_name @required(action: THROW)
    last_name @required(action: THROW)
    uuid @required(action: THROW)
  }
`;
