import * as Sentry from '@sentry/react';
// import useRudderStackAnalytics from '../../../hooks/useRudderStackAnalytics';

enum EventTitles {
  createaccount = 'Account Created',
  purchase = 'Order Completed',
  signout = 'Signed Out',
}

export const accountcreatedRd = () => {
  // eslint-disable-next-line no-console
  console.log('rudderstack: account created');
  // const analytics = useRudderStackAnalytics();
  // analytics.track('Account Created');
};

export const purchaseRd = () => {
  // eslint-disable-next-line no-console
  console.log('purchase rd');
};

// step 1 of the logout, make track call so we get the event in their event history
export const logoutRdStep1 = () => {
  try {
  // @ts-ignore type mismatch for RudderAnalytics
    window.rudderanalytics.ready(() => {
    // @ts-ignore type mismatch for RudderAnalytics
      window.rudderanalytics.track(EventTitles.signout);
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

// step 2 of the logout, actually remove user data from the browser
export const logoutRdStep2 = () => {
  try {
  // @ts-ignore type mismatch for RudderAnalytics
    window.rudderanalytics.ready(() => {
    // @ts-ignore type mismatch for RudderAnalytics
      window.rudderanalytics.reset();
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};
