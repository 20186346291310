import { graphql } from 'babel-plugin-relay/macro';

export const StuSubsGetprorationamountMutation = graphql`
  mutation StuSubsGetprorationamountMutation($newPlanId: Int!) {
  stu_subs_getprorationamount(newPlanId: $newPlanId) {
    data {
      amountDue
    }
    success
  }
}
`;
