import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { accountcreatedFb, purchaseFb } from './facebook';
import { accountcreatedPg, purchasePg } from './posthog';
import { accountcreatedIn, purchaseIn } from './intercom';
// import { accountcreatedRd, purchaseRd } from './rudderstack';

// #region types

// new user account created data required
export interface EventdtAccountCreated {
  // the user's first name
  firstName: string,
  // the user's last name
  lastName: string,
  // the user's email address e.g. "hi@gmail.com"
  email: string,
  // the event uuid, in this case this is the `uuid` from the users table. this is very
  // important for facebook to deduplicate the event; the event will be sent here from
  // our frontend AND from our backend. if we don't dedupe, then fb will count it as two
  // separate registrations
  eventuuid: string,
  // our main user identifier from the db
  userid: string,
}

// purchase event data
export interface EventdtPurchase {
  // how much the purchase was for
  value: number,
  // the event uuid, in this case this is the `uuid` from the orders table. this is very
  // important for facebook to deduplicate the event; the event will be sent here from
  // our frontend AND from our backend. if we don't dedupe, then fb will count it as two
  // separate registrations
  eventuuid: string,
}

// #endregion

// #region data validations

// validate data for account created
const validateAccountCreatedData = (dt: EventdtAccountCreated) => {
  if (dt.email.length < 1) {
    Sentry.captureException(
      new Error('eventAccountCreated email has an error'),
      {
        extra: {
          em: dt.email,
          id: dt.eventuuid,
        }
      }
    );
  }

  if (dt.eventuuid.length < 7) {
    Sentry.captureException(
      new Error('eventAccountCreated eventuuid has an error'),
      {
        extra: {
          em: dt.email,
          id: dt.eventuuid,
        }
      }
    );
  }

  if (dt.userid.length < 5) {
    Sentry.captureException(
      new Error('eventAccountCreated userid has an error'),
      {
        extra: {
          userid: dt.userid,
          id: dt.eventuuid,
        }
      }
    );
  }

  if (dt.firstName.length < 1) {
    Sentry.captureException(
      new Error('eventAccountCreated firstName has an error'),
      {
        extra: {
          userid: dt.userid,
          id: dt.eventuuid,
          firstName: dt.firstName,
        }
      }
    );
  }

  if (dt.lastName.length < 1) {
    Sentry.captureException(
      new Error('eventAccountCreated lastName has an error'),
      {
        extra: {
          userid: dt.userid,
          id: dt.eventuuid,
          firstName: dt.lastName,
        }
      }
    );
  }
};

// validate the purchase data
const validatePurchaseData = (dt: EventdtPurchase) => {
  try {
    if (dt.eventuuid.length < 7) {
      Sentry.captureException(
        new Error('eventPurchase eventuuid has an error'),
        {
          extra: {
            id: dt.eventuuid,
          }
        }
      );
    }

    if (typeof dt.value !== 'number') {
      Sentry.captureException(
        new Error('eventPurchase value is not a number'),
        {
          extra: {
            val: dt.value,
          }
        }
      );
    }

    if (dt.value <= 0) {
      Sentry.captureException(
        new Error('eventPurchase value has a < 0 error'),
        {
          extra: {
            val: dt.value,
          }
        }
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

// #endregion

export const useEventLogger = () => {
  const posthog = usePostHog();

  // user account created
  const evtAccountCreated = (dt: EventdtAccountCreated) => {
    try {
      accountcreatedIn(dt);
      accountcreatedFb(dt);
      accountcreatedPg(posthog, dt);
      // accountcreatedRd();

      // note that we do these validations *after* actually sending the events. that way the
      // events still get sent even if there's a problem with the data; hopefully the problem
      // isnt bad enough to make the data useless
      validateAccountCreatedData(dt);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // purchase
  const evtPurchase = (dt: EventdtPurchase) => {
    try {
      purchaseIn(dt);
      purchaseFb(dt);
      purchasePg(posthog, dt);
      // purchaseRd();

      // note that we do these validations *after* actually sending the events. that way the
      // events still get sent even if there's a problem with the data; hopefully the problem
      // isnt bad enough to make the data useless
      validatePurchaseData(dt);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // start session recording
  const evtStartSessionRecording = () => {
    try {
      posthog.startSessionRecording();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return {
    evtAccountCreated, evtPurchase, evtStartSessionRecording,
  };
};
