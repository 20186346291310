import React, { useState } from 'react';
import {
  Grid2Ct, Grid2, CardStandard, Ty, IcSvgList, CardSmallColor, Link, Dialog, Ic,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { LayoutPosttrial0 } from '../../posttrial/LayoutPosttrial0';
import { sitedata } from '../../../../../../utils/sitedata';

/* student has completed a trial lesson. Explain how they can schedule more or purchase
*/
export const TrialCompleted = () => {
  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* next steps, group classes */}
      <Grid2Ct sx={{ mb: 1 }}>
        {/* next steps */}
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <CardStandard
            titleText="Next Steps!"
            titleIcon={IcSvgList.star1}
            color="accentBlue1"
            titleIconRight={0}
            cp={{
              sx: {
                width: '100%'
              }
            }}
          >
            <Grid2Ct>
              {/* text */}
              <Grid2 xs={12}>
                <Ty>
                  We really hope you enjoyed meeting with your teacher. If you did, you can
                  purchase some lessons and continue with them!
                </Ty>
              </Grid2>

              {/* card color - purchase */}
              <Grid2 xs={12} sm={6} md={12} xl={6}>
                <Link
                  to={sitedata.url.app.purchaseHome}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <CardSmallColor
                    text="Purchase"
                    icon={IcSvgList.purchase1}
                    color="accentGreen1"
                    hovercursor="pointer"
                  />
                </Link>
              </Grid2>

              {/* card color - try more */}
              <Grid2 xs={12} sm={6} md={12} xl={6}>
                <CardSmallColor
                  text="Try More"
                  icon={IcSvgList.group1}
                  color="accentBlue1"
                  hovercursor="pointer"
                  onClick={handleModalOpen}
                />
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </Grid2>

        {/* group classes */}
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <GroupClasses />
        </Grid2>
      </Grid2Ct>

      {/* normal display */}
      <LayoutPosttrial0 />

      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;Try New Teachers Any Time, Even Years From Now!</Ty>
            <Ty>
              If you enjoyed your trial lesson, great, purchase lessons and continue with
              that teacher!
              At any time &ndash; tomorrow, next week, even years from now &ndash; you can
              try lessons with other teachers completely for  free. Just go to the &quot;Discover
              Teachers&quot; page to browse teacher profiles and schedule a lesson with anyone.
            </Ty>
            <br />
            <Ty v="h2New"><Ic iconName="question" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Where is the Discover Teachers Page?</Ty>
            <Ty>
              You&apos;ll find a link to it on the main Lessons page of your account. Head there to
              see all of our teachers who are currently accepting new students!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// #region subcomponents

const GroupClasses = () => (
  <CardStandard
    titleText="Bite-Sized Conversational Classes?"
    titleIcon={IcSvgList.group1}
    titleIconRight={0}
    color="accentBlue1"
    cp={{ sx: { width: '100%' } }}
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty>
          If you&apos;re on a budget, or if you might enjoy learning with a small group
          of other students, check out classes!
        </Ty>
      </Grid2>
      <Grid2 xs={12} sm={6} md={12} xl={6}>
        <Link
          to={sitedata.url.app.grouplsnUpcomingDt.pathFull}
          component={NavLink}
          linkStyle="nostyle"
        >
          <CardSmallColor
            text="View Schedule"
            icon={IcSvgList.calendar1}
            color="accentPurple1"
            hovercursor="pointer"
          />
        </Link>
      </Grid2>
      <Grid2 xs={12} sm={6} md={12} xl={6}>
        <Link
          to={sitedata.url.app.purchaseHomeDt.pathFull}
          component={NavLink}
          linkStyle="nostyle"
        >
          <CardSmallColor
            text="Try For Free!"
            icon={IcSvgList.cart2}
            color="accentBlue1"
            hovercursor="pointer"
          />
        </Link>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

// #endregion
