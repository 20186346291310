export const generateUniqueId = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = Math.random() * 16 | 0; const
    // eslint-disable-next-line no-bitwise, eqeqeq, no-mixed-operators
    v = c == 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const refreshPage = () => {
  // Don't do complete relead, just do cache version of browser reload...
  window.location.reload();
};

// the timeout value for api calls throughout our app!
export const getTimeValue = (name: string) => {
  // on our test site we allow longer timeouts
  if (process.env.NODE_ENV === 'development') {
    return 15000;
  }

  // TODO: very dangerous, because simply changing the api call name will cause this code to not
  //  work anymore. the user editing the names of these api calls won't know this is here!
  // these two api calls might take longer then the rest, because these includes creating order
  // in paypal and processing card for payment which might take some time, that's why we are
  // increasing the time here.
  if (name === 'CreateOrderForPurchaseMutation' || name === 'CapturePaymentMutation') {
    return 10000;
  }

  // getting user's remaining credits is a slow php operation
  if (name === 'HoursReaminingMutation') {
    return 8000;
  }

  // default api call timeout
  return 5000;
};
