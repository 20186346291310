import React, { useEffect } from 'react';
import {
  CardContent, Grid2, Grid2Ct, Card, Ty, useTheme, Button, Ic, Box,
  IcSvg, IcSvgList,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import IframeResizer from 'iframe-resizer-react';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ImageMainProfile } from '../TeachersProfile.style';
import { sitedata } from '../../../../../utils/sitedata';
import { useFavoriteTeacher } from '../../../../../common/hooks/teachers/favorites/useFavoriteTeacher';
import { RequiredAccountSettings } from './RequiredAccountSettings';
import { TeacherProfileLoader } from './TeacherProfileLoader';
import { RelayFavoritedVals } from '../../../../../common/relay/resolvers/teachers/relayFavorited';
import { CardContentNopadding } from '../../../../../common/components/card/Card';

export type TeacherProfileProps = {
  tchId: string
  tabToShow: number
  teacherProfileData: any
  userInfo: any
  waitingForLoading: boolean
}

const TeacherProfile = ({
  tchId,
  tabToShow,
  teacherProfileData,
  userInfo,
  waitingForLoading
}: TeacherProfileProps) => {
  // #region general

  const theme = useTheme();
  const { t } = useTranslation('languageLocations');
  const br = theme?.ourTheme.borders.borderRadius.medium;
  const imgBr = theme?.ourTheme.borders.borderRadius.images;
  const tchName = teacherProfileData.title;
  const lang_teacher_teach_id = teacherProfileData.instructor_languages.language_id;

  // #endregion

  // #region user data required for scheduling a trial lesson

  const userDOB = userInfo.dob;
  const passwordSet = userInfo.password_set;
  const timezoneSet = userInfo.timezone_set;
  let accountSettingsToShow = 0;
  if (passwordSet !== 1 || timezoneSet !== 1 || userDOB === null) {
    accountSettingsToShow = 1;
  }

  // #endregion

  // #region favorite functionality

  // boolean, isFavorite is used to display whether or not the teacher is a favorite.
  // recall that the relayFavorited property on resources in relay is our source of truth
  // for "is this teacher one of the students favorites?"
  let isFavorite = false;
  if (teacherProfileData.relayFavorited === RelayFavoritedVals.IsFavorite) {
    isFavorite = true;
  }

  // our hook which adds or removes the teacher from the student's favorites, depending
  // on the current value of relayFavorited. does this with an optimistic update
  const { favoriteTeacher } = useFavoriteTeacher();
  const handlefavouriteTeacher = () => {
    favoriteTeacher(
      teacherProfileData.relayFavorited,
      teacherProfileData.uuid,
      teacherProfileData.id,
    );
  };

  // #endregion

  // #region about teacher (name, country, etc.)

  // TODO: country name is hardcoded into our db. it's the english name. we need to put
  // country into translations files instead
  // city, country. handle situations that one or both are empty
  let tchCityCountry = '';
  // we have both city and country
  if (teacherProfileData.stuprof_city && teacherProfileData.stuprof_country) {
    tchCityCountry = `${teacherProfileData.stuprof_city}, ${teacherProfileData.stuprof_country.common_name}`;

    // we have country, but not city (note: we will not show anything if the teacher has listed
    // city, but not country)
  } else if (teacherProfileData.stuprof_country) {
    tchCityCountry = `${teacherProfileData.stuprof_country.common_name}`;
  }

  // about me
  const tchAboutme = teacherProfileData.stuprof_aboutme;
  const getNewStudent = teacherProfileData.gets_new_students;
  const wantsNewStudent = teacherProfileData.wants_new_students;
  // the teacher provides free trial lessons to new students, if provides_free_trial = 1
  const providesFreeTrial = teacherProfileData.provides_free_trial === 1;
  // we only want to show whether the teacher provides a free trial or not, if the teacher
  // can get and wants to get new students
  let showFreeTrialInfo = false;
  if (getNewStudent === 1 && wantsNewStudent === 1) {
    showFreeTrialInfo = true;
  }

  // #endregion

  // #region teacher images

  // if the teacher hasn't uploaded *any* images yet, we'll show the default image. otherwise
  // the main image is the one with lowest sort order
  let mainImg = `${process.env.REACT_APP_IMAGESCDNURL}${sitedata.assets.imgDefaultProfile}`;
  if (teacherProfileData.resources_files_profileimgs.length > 0) {
    mainImg = `${teacherProfileData.resources_files_profileimgs[0].fuploads.file_cdn.base_url}${teacherProfileData.resources_files_profileimgs[0].fuploads.file_path}`;
  }
  // images other than the very first one
  const moreImages: string[] = [];
  if (teacherProfileData.resources_files_profileimgs.length > 1) {
    // get all images except the first one
    const allImages = [...teacherProfileData.resources_files_profileimgs];
    allImages.shift();
    // loop through each, creating url to the image
    allImages.forEach((el: any) => {
      const url = `${el.fuploads.file_cdn.base_url}${el.fuploads.file_path}`;
      moreImages.push(url);
    });
  }

  // #endregion

  // #region to scroll user to top when page loads in iframe
  useEffect(() => {
    /**
       * we only wanted to do scrolling when the user has selected the schedule tab, that's why we
       * have this condition here which allow the scrollIntoView function to only execute if the
       * current tab is 2.
      */
    if (tabToShow === 2) {
      const iframe: any = document.getElementById('scheduleSingleLesson');

      // iframe.addEventListener was throwing an error in our e2e tests, we think because the iframe
      // may not always be available on the page immediately? to stop the entire page from erroring
      // we'll catch and log this error, hopefully fix it in future once we know the exact cause
      try {
        iframe.addEventListener('load', () => {
          const elementToScroll = document.querySelector('[data-top="top"]');
          scrollIntoView(elementToScroll!, {
            behavior: 'auto',
            // block: start attempts to scroll the element into the top of the viewport
            block: 'start',
            inline: 'nearest',
          });
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [tabToShow]);

  // #endregoin

  return (
    <>
      {/* conditionally show loader for teacher schedule page */}
      {(
        waitingForLoading && accountSettingsToShow !== 1 && tabToShow === 2
      ) && <TeacherProfileLoader />}

      {/* main content - profile */}
      <Grid2 xs={12} sx={tabToShow === 1 ? { display: 'block' } : { display: 'none' }}>
        <Card cp={{ 'data-testid': 'teacherProfilePage' }}>
          <CardContent>
            <Grid2Ct>
              {/* main photo */}
              <MainPhotoContainer>
                <Box display="flex" justifyContent="center">
                  <ImageMainProfile
                    src={mainImg}
                    alt={tchName}
                    style={{ borderRadius: imgBr }}
                  />
                </Box>
              </MainPhotoContainer>

              {/* name, about, more images */}
              <RightContentContainer>
                {/* name, fav button, about teacher */}
                <Grid2Ct>
                  {/* name */}
                  <Grid2 xs={12} md={7} xl={8}>
                    <Ty v="h2New">{tchName}</Ty>
                  </Grid2>

                  {/* fav button, large screens */}
                  <Grid2 xs={12} md={5} xl={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      {isFavorite ? (<><IcSvg icon={IcSvgList.star1} height="40px" width="40px" />&nbsp;&nbsp;&nbsp;&nbsp;</>) : null }
                      <Button
                        color="accentPurple1"
                        onClick={() => handlefavouriteTeacher()}
                      ><Ic iconName="user-graduate" iconStyle={isFavorite ? 'duotone' : 'light'} color="accentPurple1Dark" />&nbsp;Favorite
                      </Button>
                    </Box>
                  </Grid2>

                  {/* fav button, small screens */}
                  <Grid2 xs={12} md={5} xl={4} sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box display="flex" sx={{ justifyContent: 'flex-start' }}>
                      <Button
                        color="accentPurple1"
                        onClick={() => handlefavouriteTeacher()}
                      ><Ic iconName="user-graduate" iconStyle={isFavorite ? 'duotone' : 'light'} color="accentPurple1Dark" />&nbsp;Favorite
                      </Button>{isFavorite ? (<>&nbsp;&nbsp;&nbsp;&nbsp;<IcSvg icon={IcSvgList.star1} height="40px" width="40px" /></>) : null }
                    </Box>
                  </Grid2>

                  {/* country, lang, accepting new students, free trial */}
                  <Grid2 xs={12}>
                    {/* country, language */}
                    <Box sx={{ mt: { xs: 1, md: 0 } }}>
                      <Ty v="subheading">{tchCityCountry}</Ty>
                      <Ty v="subheading">Teaches {t(`${lang_teacher_teach_id}`)}</Ty>
                    </Box>

                    {/* accepting new students, free trial */}
                    <Box sx={{ mt: 2 }}>
                      {/* whether or not the teacher wants and is able to receive new students */}
                      {getNewStudent === 1 && wantsNewStudent === 1 ? (
                        <Ty removeMb>
                          <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>👋 This teacher is accepting new students</Box>
                          <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>👋 Accepting new students</Box>
                        </Ty>
                      )
                        : (
                          <Ty removeMb>
                            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>❌ This teacher is not currently accepting new students</Box>
                            <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>❌ Not currently accepting new students</Box>
                          </Ty>
                        )}

                      {/* here we show whether or not the teacher provides a free trial, iff
                      they are accepting new students. showFreeTrialInfo gives us that info
                    */}
                      {showFreeTrialInfo && providesFreeTrial ? (
                        <Ty removeMb>
                          <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>🎉 Offers a 100% free trial lesson to new students</Box>
                          <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>🎉 Offers a 100% free trial lesson</Box>
                        </Ty>
                      )
                        : (null)}
                      {showFreeTrialInfo && !providesFreeTrial ? (
                        <Ty removeMb>
                          <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>🎓 Asks for a small payment for trial lessons</Box>
                          <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>🎓 Small payment for trial lesson</Box>
                        </Ty>
                      )
                        : (null)}
                    </Box>
                  </Grid2>

                  {/* about me */}
                  <Grid2 xs={12} sx={{ mt: 1 }}>
                    <Ty v="p">{tchAboutme}</Ty>
                  </Grid2>
                </Grid2Ct>

                {/* more images */}
                <Grid2Ct>
                  {moreImages.map((object) => (
                    <Grid2 xs={6} sm={4}>
                      <ImageMainProfile
                        src={object}
                        alt={tchName}
                        style={{ borderRadius: imgBr }}
                      />
                    </Grid2>
                  ))}
                </Grid2Ct>
              </RightContentContainer>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* main content - schedule a single lesson */}
      <Grid2 xs={12} sx={tabToShow === 2 ? { display: 'block' } : { display: 'none' }}>
        {accountSettingsToShow === 1 ? <RequiredAccountSettings userInfo={userInfo} /> : (
          <Card cp={{
            sx: {
              visibility: waitingForLoading ? 'hidden' : 'visible',
            }
          }}
          >
            <CardContentNopadding>
              {/* schedule a single lesson */}
              <Grid2Ct>
                <Grid2 xs={12}>
                  <IframeResizer
                    id="scheduleSingleLesson"
                    src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=single_lesson/schedule&tch=${tchId}`}
                    style={{
                      width: '1px',
                      minWidth: '100%',
                      minHeight: '90vh',
                      borderRadius: `${br}px`,
                      border: 'none',
                      visibility: waitingForLoading ? 'hidden' : 'visible',
                    }}
                  />
                </Grid2>
              </Grid2Ct>
            </CardContentNopadding>
          </Card>
        )}
      </Grid2>

      {/* main content - weekly reservation */}
      <Grid2 xs={12} sx={tabToShow === 3 ? { display: 'block' } : { display: 'none' }}>
        {accountSettingsToShow === 1 ? <RequiredAccountSettings userInfo={userInfo} /> : (
          <Card>
            <CardContentNopadding>
              <IframeResizer
                src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=customer/recurring&tch=${tchId}`}
                style={{
                  width: '1px',
                  minWidth: '100%',
                  minHeight: '90vh',
                  borderRadius: `${br}px`,
                  border: 'none',
                }}
              />
            </CardContentNopadding>
          </Card>
        ) }
      </Grid2>
    </>
  );
};

// the teachers main profile photo
const MainPhotoContainer = ({ children }: any) => (
  <Grid2 xs={12} md={4}>
    {children}
  </Grid2>
);

// the content to the right of the main photo
const RightContentContainer = ({ children }: any) => (
  <Grid2 xs={12} md={8}>
    {children}
  </Grid2>
);

export default TeacherProfile;
