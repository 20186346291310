import { graphql } from 'babel-plugin-relay/macro';

export const GetUserCurrentPlanSubscription = graphql`
  subscription GetUserCurrentPlanSubscription {
    subs_connection {
      edges {
        node {
          ...GetUserCurrentPlanFragment
        }
      }
    }
  }
`;

/**
 * fragment to fetch specific fields from the subs table
 * fragment includes the current_plan
 * current_plan_status and order
 * which can be reused in multiple components if needed.
 */

/**
 * *current_plan: This will tell us what plan user is currently on.
 *  This will be null if user is currently not on any plan.
 * * current_plan_status: This field will be null if the user does not have a subscription
 *  in Stripe. If they do have a subscription, it will be non-null.
 * current_plan_status can have values between (1~3) 
 * *1: trialing
 * *2: Active
 * *3: Payment past_due
 * * trialstart_ts: if user has never taken trial it will be null else it will have some timeStamp
 * *subs_mainplans.order will hold the value of user upcoming plan which is scheduled either upgrade
 * or downgrade
 */
export const GetUserCurrentPlanFragment = graphql`
  fragment GetUserCurrentPlanFragment on subs {
    current_plan
    current_plan_status
    trialstart_ts
  }
`;
