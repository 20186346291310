import { graphql } from 'babel-plugin-relay/macro';

export const GetUserData = graphql`
  fragment GetUserData on query_root {
    users_connection {
      edges {
        node {
          pk
          first_name
          last_name
          email
          user_timezone
          hour_cycle
          uuid
          random_id_public
        }
      }
    }
  }
`;
