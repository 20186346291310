import React, { useEffect } from 'react';
import { useClientQuery, useFragment, useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LayoutPosttrial0 } from './components/posttrial/LayoutPosttrial0';
import { StuSettingsGettrialdataClientQuery, StuSettingsGettrialdataTrialFragment } from '../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { StuSettingsGettrialdataTrialFragment$key } from '../../../common/relay/mutations/students/__generated__/StuSettingsGettrialdataTrialFragment.graphql';
import { LayoutNocredits1Getlangdata } from './components/pretrial/LayoutNocredits1Getlangdata';
import { useEventLogger, EventdtAccountCreated } from '../../../common/utils/eventmanager/eventmanager';
import { HomepageloadQuery, HomepageloadAcctcreateFragment } from './relay/general/Homepage';
import { HomepageloadAcctcreateFragment$key } from './relay/general/__generated__/HomepageloadAcctcreateFragment.graphql';

export const Home2 = () => {
  // #region get trial data from relay store

  /* As a first step, we get trial data from the relay store, never making an api call
      here. Why? All we need at this point is the hasCredits value. If the user does
      have credits, we display our normal view for paying customer. If the user does
      *not* have credits, the next thing we'll do is make an api call to get updated
      trial data; we have make this api call every time so that we ensure we always
      have updated, correct trial data
  */
  const response: any = useClientQuery(
    StuSettingsGettrialdataClientQuery,
    {}
  );
  const fragmentRef: StuSettingsGettrialdataTrialFragment$key = response.RelayAppSettings;
  const data = useFragment(StuSettingsGettrialdataTrialFragment, fragmentRef);

  // TODO: remove this, it's for testing
  try {
    if (data?.trialData?.hasCredits !== true && data?.trialData?.hasCredits !== false) {
      Sentry.captureException(
        new Error('MAJOR ERROR: on Home page, trialData.hasCredits did not have expected value'),
        {
          extra: {
            dt: data,
          }
        }
      );
    }
  } catch {
    // do nothing
  }

  // #endregion

  // #region tag manager event: account created

  const { evtAccountCreated } = useEventLogger();

  // data needed for the event
  const rez: any = useLazyLoadQuery(
    HomepageloadQuery,
    {}
  );
  const frag: HomepageloadAcctcreateFragment$key = rez.users_connection.edges[0].node;
  const userDt = useFragment(HomepageloadAcctcreateFragment, frag);

  // new user account created
  // when a user signs up, we forward them to the home page with ?acctcrt=1 in the url
  // so that here, we can detect this event and call our tag manager. if the user
  // refreshes the page, this event would be called again so to avoid that we update
  // the url to ?acctcrt=2. then if the user refreshes, this event will not fire again
  const [searchParams, setSearchParams] = useSearchParams();
  const acctcrt = searchParams.get('acctcrt');
  useEffect(() => {
    if (acctcrt === '1') {
      const dt: EventdtAccountCreated = {
        firstName: userDt.first_name,
        lastName: userDt.last_name,
        email: userDt.email,
        eventuuid: userDt.uuid,
        userid: userDt.random_id_public,
      };
      setSearchParams({ acctcrt: '2' });
      evtAccountCreated(dt);
    }
  }, [
    acctcrt,
    setSearchParams,
    userDt.email,
    userDt.first_name,
    userDt.last_name,
    userDt.uuid,
    userDt.random_id_public,
    evtAccountCreated,
  ]);

  // #endregion

  // if the user has credits but there is no teacher who is giving trial lesson, 
  // we'll show the normal layout.
  let displayScenario = 2;
  if (data.trialData?.hasCredits === true) {
    displayScenario = 1;
  }

  return (
    displayScenario === 1 ? (<LayoutPosttrial0 />)
      : (<LayoutNocredits1Getlangdata />)
  );
};
