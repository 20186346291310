import React, { Suspense } from 'react';
import { Home2 } from './Home2';

export const Home1 = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    // note that our loading state for now is just a blank page. it looks pretty good like that, but
    // in future we could create glimmers if we want
    <Suspense fallback={<span> </span>}>
      <Home2 />
    </Suspense>
  );
};
