import React, { Suspense } from 'react';
import { GetUserCurrentPlanData } from './ManageSubscription1';

export const ManageSubscriptionMainContainer = () => {
  // no access on production
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    // TODO: subscription design the loader for this page
    <Suspense fallback={<div />}>
      <GetUserCurrentPlanData />
    </Suspense>
  );
};
