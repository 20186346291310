import React, { useState } from 'react';
import {
  AvatarIcon,
  Popover,
  List,
  Box,
  Divider,
  ListItemTextCustom,
  ListItemButtonCustom,
  Ty,
} from '@languageconvo/wcl';
import {
  useNavigate
} from 'react-router-dom';
import { useFragment } from 'react-relay';
import useAuth from '../../../../../hooks/useAuth';
import useSettings from '../../../../../hooks/useSettings';
import { GetUserData } from '../../../../../common/relay/queries/GetUserData';
import { removeJWTFromLocalStorageAndLogoutUser } from '../../../../../utils/logout';

export const AccountPopover = ({ fragmentRef }: any) => {
  const navigate = useNavigate();
  const { setLogoutInProgress } = useAuth();
  const { themeMode, onToggleMode } = useSettings();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const userData = useFragment(GetUserData, fragmentRef);
  const userInfo = userData?.users_connection?.edges[0]?.node;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (link?: string) => {
    setOpen(null);
    if (typeof link === 'string') {
      navigate(link);
    }
  };

  return (
    <>
      {/* TODO: delete this component entirely. for now it's hidden, just so we have time
        to be sure we don't break any other part of the app */}
      {/* our own avatarIcon with some background color and icon color inside it. */}
      <Box sx={{ display: 'none' }}>
        <AvatarIcon iconName="gear" handleOpen={handleOpen} backgroundColor="primary" />
      </Box>
      <Popover
        isOpen={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        width={230}
      >
        <List disablePadding>
          <Box
            sx={{
              margin: '18px 18px 0px 32px'
            }}
          >
            <Ty noWrap>{`${userInfo?.first_name} ${userInfo?.last_name}`}</Ty>
          </Box>
          <Box
            sx={{
              margin: '6px 18px 12px 32px'
            }}
          >
            <Ty noWrap>{`${userInfo?.email}`}</Ty>
          </Box>
        </List>
        <Divider strength="thin" />
        <List disablePadding>
          <ListItemButtonCustom
            onClick={onToggleMode}
            size="large"
            color="primary"
          >
            <ListItemTextCustom
              size="large"
              disableTypography
              primary={`${themeMode === 'light' ? 'Light Mode' : 'Dark Mode'}`}
            />
          </ListItemButtonCustom>
        </List>
        <Divider strength="thin" />
        <List disablePadding>
          <ListItemButtonCustom
            onClick={() => removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress)}
            size="large"
            color="primary"
          >
            <ListItemTextCustom
              size="large"
              disableTypography
              primary="Log Out"
            />
          </ListItemButtonCustom>
        </List>
      </Popover>
    </>
  );
};
