/**
 * @generated SignedSource<<d042e9fc66818310add93403cf047ea5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserCurrentPlanFragment$data = {
  readonly current_plan: number | null;
  readonly current_plan_status: any | null;
  readonly trialstart_ts: any | null;
  readonly " $fragmentType": "GetUserCurrentPlanFragment";
};
export type GetUserCurrentPlanFragment$key = {
  readonly " $data"?: GetUserCurrentPlanFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetUserCurrentPlanFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetUserCurrentPlanFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current_plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current_plan_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialstart_ts",
      "storageKey": null
    }
  ],
  "type": "subs",
  "abstractKey": null
};

(node as any).hash = "1c545bafc6f1732fe11d513adc3a2467";

export default node;
