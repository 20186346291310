import { graphql } from 'babel-plugin-relay/macro';

export const StuSubsCreatecheckoutlinkForManageSubsPage = graphql`
  mutation StuSubsCreatecheckoutlinkForManageSubsPageMutation {
    stu_subs_createcheckoutlink {
      checkoutUrl
      checkoutUrls {
        basic
        premium
      }
      errors
      success
    }
  }
`;
