/**
 * @generated SignedSource<<5b3791c2f8e38b781ad571143700b14f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSubsCreatecheckoutlinkForManageSubsPageMutation$variables = {};
export type StuSubsCreatecheckoutlinkForManageSubsPageMutation$data = {
  readonly stu_subs_createcheckoutlink: {
    readonly checkoutUrl: string;
    readonly checkoutUrls: {
      readonly basic: string;
      readonly premium: string;
    };
    readonly errors: ReadonlyArray<string | null>;
    readonly success: boolean;
  } | null;
};
export type StuSubsCreatecheckoutlinkForManageSubsPageMutation = {
  response: StuSubsCreatecheckoutlinkForManageSubsPageMutation$data;
  variables: StuSubsCreatecheckoutlinkForManageSubsPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuSubsCreatecheckoutlinkOutput",
    "kind": "LinkedField",
    "name": "stu_subs_createcheckoutlink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkoutUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StuSubsCreatecheckoutlinkOutputcheckouturls",
        "kind": "LinkedField",
        "name": "checkoutUrls",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premium",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSubsCreatecheckoutlinkForManageSubsPageMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StuSubsCreatecheckoutlinkForManageSubsPageMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "79fdf7f5738bf73ed2880b69f6e3e410",
    "id": null,
    "metadata": {},
    "name": "StuSubsCreatecheckoutlinkForManageSubsPageMutation",
    "operationKind": "mutation",
    "text": "mutation StuSubsCreatecheckoutlinkForManageSubsPageMutation {\n  stu_subs_createcheckoutlink {\n    checkoutUrl\n    checkoutUrls {\n      basic\n      premium\n    }\n    errors\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "212a6a015c53f14dfdc24e4680297090";

export default node;
