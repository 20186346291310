/**
 * @generated SignedSource<<6b8b4716ec61172d163e5664949fcd40>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSubsCreateportalURLMutation$variables = {};
export type StuSubsCreateportalURLMutation$data = {
  readonly stu_subs_createportalurl: {
    readonly errors: ReadonlyArray<string | null>;
    readonly portalUrl: string;
    readonly success: boolean;
  } | null;
};
export type StuSubsCreateportalURLMutation = {
  response: StuSubsCreateportalURLMutation$data;
  variables: StuSubsCreateportalURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuSubsCreateportalurlOutput",
    "kind": "LinkedField",
    "name": "stu_subs_createportalurl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "portalUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSubsCreateportalURLMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StuSubsCreateportalURLMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "207883ae6f3050f6a4228f56d8d5948a",
    "id": null,
    "metadata": {},
    "name": "StuSubsCreateportalURLMutation",
    "operationKind": "mutation",
    "text": "mutation StuSubsCreateportalURLMutation {\n  stu_subs_createportalurl {\n    portalUrl\n    errors\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "85def9ccabf81eed112c6d3aa811225a";

export default node;
