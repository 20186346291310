/**
 * @generated SignedSource<<d1c9693ca15169325702118904a057d1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserRecentActionQuery$variables = {};
export type GetUserRecentActionQuery$data = {
  readonly subs_mainupdate_history_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"GetUserRecentActionFragment">;
  };
};
export type GetUserRecentActionQuery = {
  response: GetUserRecentActionQuery$data;
  variables: GetUserRecentActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "ts_user_action": "desc"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUserRecentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "subs_mainupdate_historyConnection",
        "kind": "LinkedField",
        "name": "subs_mainupdate_history_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetUserRecentActionFragment"
          }
        ],
        "storageKey": "subs_mainupdate_history_connection(first:1,order_by:{\"ts_user_action\":\"desc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetUserRecentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "subs_mainupdate_historyConnection",
        "kind": "LinkedField",
        "name": "subs_mainupdate_history_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subs_mainupdate_historyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs_mainupdate_history",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs_mainplans",
                    "kind": "LinkedField",
                    "name": "subs_mainplans",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "order",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ts_processed",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subs_mainupdate_history_connection(first:1,order_by:{\"ts_user_action\":\"desc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "af4509d7e189fedd64d85ed5f2764db2",
    "id": null,
    "metadata": {},
    "name": "GetUserRecentActionQuery",
    "operationKind": "query",
    "text": "query GetUserRecentActionQuery {\n  subs_mainupdate_history_connection(order_by: {ts_user_action: desc}, first: 1) {\n    ...GetUserRecentActionFragment\n  }\n}\n\nfragment GetUserRecentActionFragment on subs_mainupdate_historyConnection {\n  edges {\n    node {\n      subs_mainplans {\n        order\n        id\n      }\n      ts_processed\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5fdaf703e386481c9497ca6b05d5d9c";

export default node;
