/**
 * @generated SignedSource<<4053b9d3add566e4348e48a2ad7d94bc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventsDataFragment$data = {
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly random_id_public: string;
  readonly " $fragmentType": "EventsDataFragment";
};
export type EventsDataFragment$key = {
  readonly " $data"?: EventsDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventsDataFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "random_id_public",
        "storageKey": null
      },
      "action": "THROW",
      "path": "random_id_public"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      "action": "THROW",
      "path": "email"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      "action": "THROW",
      "path": "first_name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      "action": "THROW",
      "path": "last_name"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "8a96d7605d68f5fc91d1c848253f0dd1";

export default node;
