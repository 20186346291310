import videos from './videos.json';
import cpNavbar from './cpNavbar.json';
import languageLocations from './languageLocations.json';
import pgLsnDiscover from './pgLsnDiscover.json';
import pgMoreSettings from './pgMoreSettings.json';

export default {
  videos,
  cpNavbar,
  languageLocations,
  pgLsnDiscover,
  pgMoreSettings
};
