import React, { useState, Suspense } from 'react';
import { showSpace } from '@intercom/messenger-js-sdk';
import {
  Grid2, Grid2Ct, Ty, CardStandard, Dialog, Link, IcSvgList, Button,
} from '@languageconvo/wcl';
import {
  NavLink
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery } from 'react-relay';
import { sitedata } from '../../../utils/sitedata';
import { removeJWTFromLocalStorageAndLogoutUser } from '../../../utils/logout';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import { UserDetailsDataQuery } from './relay/UserDetailsData';
import { UserDetailsComponent, UserDetailsLoading, UserDetailsError } from './components/UserDetails';

export const SettingsHome = () => {
  // #region general setup

  // settings page link object
  const clickableTypeObjSettings = {
    to: sitedata.url.app.moreSettings,
    component: NavLink,
  };

  // my files link object
  const clickableTypeObjMyfiles = {
    to: sitedata.url.app.moreFiles,
    component: NavLink,
  };

  // audiovideo link object
  const clickableTypeObjAudiovideo = {
    href: sitedata.url.app.moreAvtest,
    newtab: true,
  };

  // when user clicks log out, this allows us to show the logout animation
  const { setLogoutInProgress } = useAuth();

  // when user changes light / dark mode
  const { onToggleMode } = useSettings();

  // #endregion

  // #region query data

  const fragmentRefUserDetails: any = useLazyLoadQuery(
    UserDetailsDataQuery,
    { }
  );

  // #endregion

  // #region customer service modal

  // customer service modal state. true=open, false=closed. closed by default
  const [custserveModalState, setCustserveModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleCustserveModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setCustserveModalState(true);
  };

  // modal close action
  const handleCustserveModalClose = () => {
    setCustserveModalState(false);
  };

  // #endregion

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        <Grid2 xs={12} sm={10} smOffset={1} md={12} mdOffset={0} xl={10} xlOffset={1}>
          <Grid2Ct>
            {/* settings. includes the UserDetailsDataQuery */}
            <Grid2
              xs={12}
              md={6}
              // display flex so that we can get this card and the customer support card to be
              // equal heights
              sx={{
                display: 'flex',
              }}
            >
              <CardStandard
                titleText="Settings"
                titleIcon={IcSvgList.settings1}
                color="accentBlue1"
                clickableLink1={{ ...clickableTypeObjSettings }}
                // with cards, width=100% needed when using display:flex on parent
                cp={{ sx: { width: '100%' } }}
              >
                {/* we wrap the entire children/contents in a link so that it's also
                clickable, making it easy for the user to click almost anywhere in the card */}
                <Link
                  to={sitedata.url.app.moreSettings}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12}>
                      {/* if getting this data fails, we don't want the entire page to error
                          so we wrap in an error boundary and fallback component */}
                      <Sentry.ErrorBoundary fallback={<UserDetailsError />}>
                        <Suspense fallback={<UserDetailsLoading />}>
                          <UserDetailsComponent
                            fragmentRef={fragmentRefUserDetails}
                          />
                        </Suspense>
                      </Sentry.ErrorBoundary>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>

            {/* customer support */}
            <Grid2
              xs={12}
              md={6}
              // display flex so that we can get this card and the settings card to be
              // equal heights
              sx={{
                display: 'flex',
              }}
            >
              <CardStandard
                titleText="Customer Support"
                titleIcon={IcSvgList.customersupport1}
                color="accentGreen1"
                titleIconRight={0}
                // onClickEntireCard={handleCustserveModalOpen}
                // onClickEntireCard={() => showSpace('help')}
                cp={{ sx: { width: '100%' } }}
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    {/* note: mt is there so that on really small screens when the buttons wrap,
                        the Email button has some vertical space between it and the Chat button
                     */}
                    <Button color="accentGreen1" onClick={() => showSpace('home')} cp={{ sx: { mt: 1 } }}>Chat</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button color="accentPurple1" onClick={() => showSpace('help')} cp={{ sx: { mt: 1 } }}>Help Center</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button color="accentOrange1" onClick={handleCustserveModalOpen} cp={{ sx: { mt: 1 } }}>Email</Button>
                  </Grid2>
                </Grid2Ct>
              </CardStandard>
            </Grid2>

            {/* dark / light mode */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Dark / Light Mode"
                titleIcon={IcSvgList.sun1}
                color="accentYellow1"
                titleIconRight={0}
                titleRemoveMb
                hovercursor="pointer"
                onClickEntireCard={onToggleMode}
              />
            </Grid2>

            {/* my files */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="My Files"
                titleIcon={IcSvgList.folder1}
                color="accentOrange1"
                titleRemoveMb
                clickableLink1={{ ...clickableTypeObjMyfiles }}
              />
            </Grid2>

            {/* audio / video test */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Audio / Video Test"
                titleIcon={IcSvgList.soundmixer1}
                color="accentPurple1"
                clickableLink2={{ ...clickableTypeObjAudiovideo }}
                titleRemoveMb
              />
            </Grid2>

            {/* log out */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Log Out"
                titleIcon={IcSvgList.exit1}
                color="accentRed1"
                titleRemoveMb
                hovercursor="pointer"
                titleIconRight={0}
                onClickEntireCard={
                  () => removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress)
                }
              />
            </Grid2>
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>

      {/* modal - customer service */}
      <Dialog
        isOpen={custserveModalState}
        onClose={handleCustserveModalClose}
        width="xs"
        color="accentOrange1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              Email us any time!
              <br /><br />
              contact@languageconvo.com
              <br /><br />
              One note, your email actually gets sent into our chat system, so it is probably a bit
              faster and easier for you to send us a chat message. When we respond to you,
              you&apos;ll get the response via email and via our chat system.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};
