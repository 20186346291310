import React, { Dispatch, SetStateAction } from 'react';
import {
  Box, Grid2, Grid2Ct, Ty, useTheme, useMediaQuery,
} from '@languageconvo/wcl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStuSettingsFromLCStorage, setStuSettingsToLCStorage } from '../../../utils/lcStorage';
import { sitedata } from '../../../utils/sitedata';
import { AlignIconText } from '../../../common/components/styling/AlignIconText';

interface CountriesProps {
  countires: any;
  setShowContinentPage:Dispatch<SetStateAction<boolean>>;
  setShouldStopRadio: Dispatch<SetStateAction<boolean>>;
  setShuffledFunFacts: any,
  setShowFullOverview: Dispatch<SetStateAction<boolean>>;
}
export const Countries = ({
  countires, setShowContinentPage, setShouldStopRadio,
  setShuffledFunFacts, setShowFullOverview
}:
   CountriesProps) => {
  // extracting `countryId` from the URL to load details of a selected country
  const [searchParams] = useSearchParams();
  const countryId = searchParams.get('id');
  const globalTourCountryIdFromParams = Number(countryId);

  const theme = useTheme();
  // IMPORTANT: if you change the deifnition of screensizeLarge here, you need to change the other
  //  place in global tour that we do the same
  // on small screens, we'll show a truncated version of the country overview
  const screensizeLarge = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const arraysToDisplay: any = [...countires];
  const lcData = getStuSettingsFromLCStorage();
  const { globalTour } = lcData;

  // sorting the countries alphabatically
  arraysToDisplay.sort((a: any, b: any) => a.country_name.localeCompare(
    b.country_name
  ));

  const handleCountryClick = (country: any) => {
    // if user clicked on the same country again after clicking change country,
    // in this case we will just hide continent page
    if (globalTourCountryIdFromParams === country.pk) {
      // hide the continent selection view since the user has selected the same country which is
      // currently viewed by him/her
      setShowContinentPage(false);

      // if user clicked any other country which is currently not selected we will do following
      // 1 - stop radio
      // 2 - hide continent page and show selected country page
      // 3 - store newly selected country in local storage
      // 4 - redirect user to the same page with new search param
    } else {
      // collapsing the overview whenever user click on country selection again IF the user
      // has a small screen size. on large screens, the overview always shows
      if (!screensizeLarge) {
        setShowFullOverview(false);
      }

      // storing the selected country_id into local storage
      const newSetting = {
        globalTour: {
          country_id: country.pk
        }
      };

      // indicate that the radio playback should be stopped when a country is selected.
      // this is important for user experience, as we don't want multiple radio stations
      // playing simultaneously when navigating between countries.
      setShouldStopRadio(true);

      // hide the continent selection view since the user is moving to a specific country's details.
      // this helps in maintaining a clean UI and focusing on the relevant
      //  information for the selected country.
      setShowContinentPage(false);

      // storing the newly created settings object in local storage.
      setStuSettingsToLCStorage(newSetting);

      // redirecting the user to selected country page
      navigate(`${sitedata.url.app.practiceGlobalTourDt.pathFull}${country.pk}`);

      // when user goes to another country set this array to [], beacuse we are not changing the
      // route so state itself won't get update, so we need to do it manually
      if (globalTour.country_id !== country.pk) {
        setShuffledFunFacts([]);
      } else {
      // do nothing,
      }
    }
  };

  return (
    <>
      {arraysToDisplay.map((country: any, index: number) => (
        <Grid2 key={country.country_name} xs={12} md={4}>
          <Box
            data-testid={`countrie-${index}`}
            onClick={() => handleCountryClick(country)}
            sx={{
              background: globalTourCountryIdFromParams === country.pk
                ? theme.ourTheme.colors.cp.bgInnerCard.accentOrange1
                : theme.ourTheme.colors.cp.bgInnerCard.accentGreen1,
              padding: '16px 24px 16px 24px',
              borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
              cursor: 'pointer'
            }}
          >
            <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid2 xs={12}>
                <AlignIconText>
                  <img src={country.flag_image} alt="flag_image" width="28px" />

                  <Ty v="h2New" removeMb cp={{ sx: { pl: 2 } }}>
                    {country.country_name}
                  </Ty>
                </AlignIconText>
              </Grid2>
              <Grid2 xs={12} sx={{ pt: 0 }}>
                <Ty removeMb>
                  {country.locations.title}
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Box>
        </Grid2>
      ))}
    </>
  );
};
