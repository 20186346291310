import React, { useEffect, useState } from 'react';
import {
  Grid2, Grid2Ct, CardStandard, Dialog, Ty,
  TextField, Button, Alert, Stack, IcSvgList,
} from '@languageconvo/wcl';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-relay';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { userGroupSettingsFields } from '../../schema/userSettingsFields';
import { UpdateUserGroupSettings } from '../../relay/GroupUserSettingsUpdate';
import { DeleteUserGroupSettings } from '../../relay/DeleteUserGroupSetting';
import { LeftCol, RightCol, FullwidthCol } from '../Common';

interface ShareMyPurchasedPackageProps {
  groupNameInitialValue: string | null
}

export const SharePackages = (props: ShareMyPurchasedPackageProps) => {
  // #region general

  const { t } = useTranslation('pgMoreSettings');
  const { groupNameInitialValue } = props;
  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control, handleSubmit, formState: { errors }, setValue, reset
  } = useForm <any>({
    mode: 'onBlur',
    resolver: yupResolver(userGroupSettingsFields),
  });

  // #endregion

  // #region update group

  useEffect(() => {
    setValue('groupName', groupNameInitialValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // mutation call for the updation of user's group settings and isPending flag to make sure
  // the api call has succeeded or not
  const [UpdateUserGroupSettingsMutation, isPending] = useMutation(UpdateUserGroupSettings);
  const [DeleteUserGroupSettingsMutation, isInFlight] = useMutation(DeleteUserGroupSettings);

  // this function gets triggered when user click on save button, it initiates the mutation call
  const MutationCallForGroupSettings = (data: any) => {
    // commit function for mutation call, takes in 3 main things
    // 1 - variables (payload)
    // 2 - onComplete function, which gets triggered when api call respond with 200 (success)
    // 3 - onError function, which gets triggered when api call respond other than 200 (500, 400)
    UpdateUserGroupSettingsMutation({
      variables: {
        groupName: data.groupName,
        groupPassword: data.groupPassword,
      },
      onCompleted: (res: any) => {
        // if all fields are updated successfully, we are displaying toast to user.
        if (res.stu_settings_groupcredscreate.success === true) {
          toast.success('Changes saved!', { autoClose: 2000 });
          setValue('groupName', data.groupName);
          setValue('groupPassword', '');
          // if any of the field has error then our errors array length will be greater than 0.
          // in this case we need to tell user that some of there fields are not gets saved
        } else if (res.stu_settings_groupcredscreate.errors.length > 0) {
          toast.error('Hmm, something went wrong. Please reload the page and verify the changes were saved.');
          // this is an unexpected case, if backend has returned something that we don't expect to
          // to get we will catch that here.
        } else {
          toast.error(
            'Hmm, something went wrong. Please reload the page and verify the changes were saved.'
          );
          // for notifying us, that something strange is happening
          Sentry.captureException(
            new Error('Something unexpected happened in new settings page share packages section'),
            { extra: { response: res } },
          );
          Sentry.captureMessage(
            'Something unexpected happened, backend is returning error without success or error nodes in user settings page'
          );
        }
      },
      onError: (err) => {
        // this is the case if backend failed to load or gave us other than 200 response,
        // this function will gets triggered, and we will show user an error toast.
        // also we will capture that exception so we are notified through sentry that something
        // strange is happening right now.
        toast.error(
          'Hmm, something went wrong. Please reload the page and verify the changes were saved.'
        );
        Sentry.captureMessage(
          'Something unexpected happened, while users is trying to save his settings. Need dev attention here'
        );
        Sentry.captureException(err);
      }
    });
  };

  // #endregion

  // #region delete group

  const deleteGroupSettings = () => {
    DeleteUserGroupSettingsMutation({
      variables: {},
      onCompleted: () => {
        toast.success('Group settings deleted!', { autoClose: 2000 });
        reset();
      },
      onError: (err) => {
        // this is the case if backend failed to load or gave us other than 200 response,
        // this function will gets triggered, and we will show user an error toast.
        // also we will capture that exception so we are notified through sentry that something
        // strange is happening right now.
        toast.error(
          'Hmm, something went wrong. Please reload the page and verify the changes were saved.'
        );
        Sentry.captureMessage(
          'Something unexpected happened, while users is trying to save his settings. Need dev attention here'
        );
        Sentry.captureException(err);
      }
    });
  };

  // #endregion

  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      <CardStandard
        titleText="Share Purchases"
        titleIcon={IcSvgList.gift1}
        color="accentPurple1"
        titleIconRight={2}
        onClickRightIcon={handleModalOpen}
      >
        <Grid2Ct sx={{ mt: 2 }}>
          {/* name, password */}
          <Grid2 xs={12}>
            <Grid2Ct>
              {/* name */}
              <LeftCol>
                <Controller
                  name="groupName"
                  control={control}
                  render={
                    ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <TextField
                        id="group-name"
                        label="Group Name"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helpertext={error ? t(`${error.message}`) : null}
                      />
                    )
                  }
                />
              </LeftCol>

              {/* password */}
              <RightCol>
                <Controller
                  name="groupPassword"
                  control={control}
                  render={
                    ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <TextField
                        id="group-password"
                        label="Group Password"
                        onChange={onChange}
                        onBlur={onBlur}
                        type="password"
                        value={value}
                        error={!!error}
                        helpertext={error ? t(`${error.message}`) : null}
                      />
                    )
                  }
                />
              </RightCol>
            </Grid2Ct>
          </Grid2>

          {/* save */}
          <Grid2 xs={12}>
            <Grid2Ct>
              <FullwidthCol>
                <Stack spacing={2} direction="row">
                  <Button
                    onClick={handleSubmit(MutationCallForGroupSettings)}
                    disabled={isPending}
                    color="accentPurple1"
                    isLoading={isPending}
                  >
                    Save Changes
                  </Button>
                  <Button
                    onClick={() => deleteGroupSettings()}
                    disabled={isInFlight || groupNameInitialValue === null}
                    color="accentPurple1"
                    isLoading={isInFlight}
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </Stack>
              </FullwidthCol>

              {Object.keys(errors).length > 0 && (
              <FullwidthCol>
                <Alert variant="standard" title="Error" severity="error">
                  One of the options you entered seems to be invalid, see above
                </Alert>
              </FullwidthCol>
              )}
            </Grid2Ct>
          </Grid2>
        </Grid2Ct>

      </CardStandard>

      {/* modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              Have a friend, family member, or colleague you want to share your lesson
              credits with? Just enter a Group Name and Group Password here (make them
              secure, not easy to guess!)
              Then have those you want to share with enter the same in their accounts.
              Voila, they will be able to schedule lessons using your purchased packages
              and vice versa!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};
