import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, Grid2, Grid2Ct, ScrollArea, Ty, IcSvg,
  IcSvgList, Button, CardSmallColor,
} from '@languageconvo/wcl';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useFragment } from 'react-relay';
import { AIChatPartnerLimitReached } from './AIChatPartnerLimitReached';
import { AlmostFullInnerHeightMostPages } from '../../../common/components/containers/AlmostFullInnerHeightMostPages';
import { AlignIconText } from '../../../common/components/styling/AlignIconText';
import { GetUserPreferencesFragment$key } from './relay/__generated__/GetUserPreferencesFragment.graphql';
import { InputComponent } from './AIChatPartner3';
import { ChangeSkillLevelDialog } from './components/userPreferencesDialog/ChangeSkillLevelDialog';
import { TranslationToggleDialog } from './components/userPreferencesDialog/TranslationToggleDialog';
import { MarkdownContainer, MyQuestionContainer } from './AIChatPartner.style';
import useAIChatPartner from './hooks/useAIChatPartner';
import { GetUserPreferencesLangLearningFragment$key } from './relay/__generated__/GetUserPreferencesLangLearningFragment.graphql';
import { GetUserPreferencesFragment } from './relay/GetUserPreferences';
import { Messages } from './enum/Messages';
import { TypingAnimation } from '../../../common/components/styling/TypingAnimation';

interface Props {
  fragmentRefForUserPrefrences: GetUserPreferencesFragment$key;
  fragmentRefForLngLearning: GetUserPreferencesLangLearningFragment$key
}

export const PracticeWithAIChatPartner = ({
  fragmentRefForUserPrefrences,
  fragmentRefForLngLearning
}: Props) => {
  // #region general for variables

  // state variable to hold student's query/question which he/she wants to ask from ai
  // we will send this to our BE end point.
  const [userInput, setUserInput] = useState<string>('');

  // state to show streaming message,
  const [streamingMessage, setStreamingMessage] = useState<string>('');

  const {
    openSkillModal, openToggleTranslationModal, setOpenSkillModal,
    setOpenToggleTranslationModal, isLimitReached, disableAskQuestionBtn,
    messages, firstQuestionAsked, isPaidLimitReached, setIsSuggestResBtnClick,
    setNoOfQuestionUserAsked, setThreadId, setMessages, isFirstResFromBE
  } = useAIChatPartner();

  // #endregion

  // #region to handle scroll into view

  // this useEffect will scroll the user's view to the latest question

  useEffect(() => {
    // getting the last index here, because i have to check if the last entered message
    // has type question, because this useEffect will run for questions and answers.
    // but we only want to scroll when the question is inserted in the UI.
    const lastMessageIndex = messages.length - 1;

    // getting the element to which we have to scroll
    const element = document.getElementById('scroll-to-bottom');

    // this condition is for making sure that the message should not be empty and the userType === 1
    // which means this is a question, also the element to which we have to scroll should not be
    // null or undefined
    if (
      messages.length > 0
      && messages[lastMessageIndex].userType === 1
      && element
    ) {
      scrollIntoView(element, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'end',
      });
    }
  }, [messages]);

  // #endregion

  // #region for SkillLevel & translation mapping

  // reading user preferences for skill level and translation settings from relay store.
  const userPreferences = useFragment(
    GetUserPreferencesFragment,
    fragmentRefForUserPrefrences
  );

  // map for skill level to display labels on the UI,
  const skillLabels: any = {
    1: 'Beginner',
    2: 'Intermediate',
    3: 'Advanced',
  };

  // `selectedSkillLevel` will hold the current skill level like(Basic, Intermediat, Advance).
  const selectedSkillLevel = skillLabels[userPreferences.aic_level];

  // map for translation to display labels on the UI,
  const translationLabels: any = {
    0: 'cancel2',
    1: 'translate1',
  };

  // `selectedTranslationOption` holds the user's chosen translation setting.
  // Derived from `translationLabels` using `aic_translate` from user preferences.
  const selectedTranslationOption = translationLabels[userPreferences.aic_translate];

  // #endregion

  // function to handle Suggest Respnonse btn click this function responsible for setting
  // user input to with this message `Help me with a response` and we triggers an API call
  // to the AI Chat Partner when isSuggestResBtnClick === true
  const handleSuggestResponse = () => {
    // as user click on button we are sending `Help me with a response`
    // to AI chat Partner
    setUserInput(Messages.SuggestResponseMsg);

    // state to track user clicked on this button based on this we are sending
    // api call to our end point
    setIsSuggestResBtnClick(true);
  };

  // function to reset the current conversation here we are reseting
  // some of state, so BE can start a new thread.
  const handleResetClick = () => {
    if (!disableAskQuestionBtn) {
    // as user click on reset button we are going to start a new thread
    // so need to reset all these state
    // rest the count of user asked question
      setNoOfQuestionUserAsked(0);
      // setThreadId to null so we can start a new thread.
      setThreadId(null);
      // reseting the previous messages
      setMessages([]);
    }
  };

  return (
    <Grid2Ct>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <AlmostFullInnerHeightMostPages minHeight={300}>
          <Card cp={{ sx: { height: '100%' } }}>
            <CardContent>
              {/* area that displays questions/answers */}
              <Box sx={{ height: 'calc(100% - 55px)' }}>
                <ScrollArea height="100%">
                  <Grid2Ct>
                    {/* settings, sm and larger */}
                    <Grid2 xs={12} sx={{ mb: 4, display: { xs: 'none', md: 'block' } }}>
                      <Ty align="center" v="subheading">Settings</Ty>
                      <Box display="flex" justifyContent="center">
                        {/* level */}
                        <CardSmallColor
                          icon="knowledge2"
                          text={selectedSkillLevel}
                          color="accentGreen1"
                          hovercursor="pointer"
                          onClick={() => setOpenSkillModal(true)}
                          cp={{ 'data-testid': 'selectedSkillBtn' }}
                        />&nbsp;&nbsp;&nbsp;

                        {/* translate */}
                        <CardSmallColor
                          icon={selectedTranslationOption}
                          text="Translate"
                          color="accentGreen1"
                          hovercursor="pointer"
                          onClick={() => setOpenToggleTranslationModal(true)}
                          cp={{ 'data-testid': 'toggleTranslationBtn' }}
                        />&nbsp;&nbsp;&nbsp;

                        {/* start a new chat */}
                        <CardSmallColor
                          icon="giraffe1"
                          text="Start New"
                          color="accentGreen1"
                          hovercursor="pointer"
                          onClick={handleResetClick}
                          cp={{ 'data-testid': 'startNewChatBtn' }}
                        />
                      </Box>
                    </Grid2>

                    {/* settings, xs */}
                    <Grid2 xs={12} sx={{ mb: 4, display: { xs: 'block', md: 'none' } }}>
                      <Grid2Ct>
                        <Grid2 xs={12}>
                          <Ty align="center" v="subheading">Settings</Ty>
                        </Grid2>

                        {/* level */}
                        <Grid2 xs={12}>
                          <CardSmallColor
                            icon="knowledge2"
                            text={selectedSkillLevel}
                            color="accentGreen1"
                            hovercursor="pointer"
                            onClick={() => setOpenSkillModal(true)}
                            cp={{ 'data-testid': 'selectedSkillBtn' }}
                          />
                        </Grid2>

                        {/* translate */}
                        <Grid2 xs={12}>
                          <CardSmallColor
                            icon={selectedTranslationOption}
                            text="Translate"
                            color="accentGreen1"
                            hovercursor="pointer"
                            onClick={() => setOpenToggleTranslationModal(true)}
                            cp={{ 'data-testid': 'toggleTranslationBtn' }}
                          />
                        </Grid2>

                        {/* start a new chat */}
                        <Grid2 xs={12}>
                          <CardSmallColor
                            icon="giraffe1"
                            text="Start New"
                            color="accentGreen1"
                            hovercursor="pointer"
                            onClick={handleResetClick}
                            cp={{ 'data-testid': 'startNewChatBtn' }}
                          />
                        </Grid2>
                      </Grid2Ct>
                    </Grid2>

                    {messages.map((msg, index) => (
                      msg.content.trim()
                        && msg.userType === 1 ? (
                          <>
                            {/* user question */}
                            {index === 0 ? null : (
                              <Grid2 xs={12} xl={9} xlOffset={3}>
                                <MyQuestionContainer>
                                  <Ty>
                                    <strong>Me</strong>
                                  </Ty>
                                  <Ty removeMb>
                                    {msg.content}
                                  </Ty>
                                </MyQuestionContainer>
                              </Grid2>
                            )}
                          </>
                        ) : msg.content.trim() && (
                          // ai response
                          <ResponseContainer msg={msg.content} />
                        )
                    ))}

                    {/* Render the streaming message only while the assistant is responding */}
                    {!isLimitReached && disableAskQuestionBtn && (
                      <ResponseContainer msg={streamingMessage} />
                    )}

                    {/* have the ai give the user an example response, only when the ai is not
                      in the process of streaming its response */}
                    {!isLimitReached && !disableAskQuestionBtn && isFirstResFromBE && (
                      <Grid2 xs={12} sx={{ pt: 0 }}>
                        <Button
                          size="small"
                          color="accentBlue1"
                          onClick={handleSuggestResponse}
                          disabled={disableAskQuestionBtn || isPaidLimitReached}
                          isLoading={disableAskQuestionBtn}
                        >
                          Suggest a Response
                        </Button>
                      </Grid2>
                    )}

                    {isLimitReached && (
                      <AIChatPartnerLimitReached />
                    )}

                    {/* showing a notification to paid user when they have asked 50 question,  */}
                    {isPaidLimitReached && (
                      <AIChatPartnerLimitReached />
                    )}

                    {/* an empty div that has a height (well, has 0 height until user asks their
                      first question). this allows us to scroll the user down to the bottom of the
                      page when they ask a question */}
                    <Grid2 xs={12}>
                      <Box id="scroll-to-bottom" sx={{ height: (firstQuestionAsked ? '150px' : '0px') }}> </Box>
                    </Grid2>
                  </Grid2Ct>
                </ScrollArea>
              </Box>

              {/* user input/ask question */}
              <InputComponent
                userInput={userInput}
                setUserInput={setUserInput}
                setStreamingMessage={setStreamingMessage}
                fragmentRefForUserPrefrences={fragmentRefForUserPrefrences}
                fragmentRefForLngLearning={fragmentRefForLngLearning}
              />
            </CardContent>
          </Card>

          {/* showing skill level dialog when user click on `Change Level` button */}
          {openSkillModal && (
            <ChangeSkillLevelDialog
              fragmentRefForUserPrefrences={fragmentRefForUserPrefrences}
            />
          )}

          {/* showing skill translationtoggle dialog when user click on `On/Off translation`
             button */}
          {openToggleTranslationModal && (
            <TranslationToggleDialog
              fragmentRefForUserPrefrences={fragmentRefForUserPrefrences}
            />
          )}

        </AlmostFullInnerHeightMostPages>
      </Grid2>
    </Grid2Ct>
  );
};

// container for each ai response
export const ResponseContainer = ({ msg }: any) => (
  <Grid2 xs={12}>
    <AlignIconText>
      <IcSvg icon={IcSvgList.giraffe1} width="24px" height="24px" />&nbsp;&nbsp;&nbsp;
      <Ty removeMb><strong>Chat Partner</strong></Ty>
    </AlignIconText>
    <MarkdownContainer>
      {msg === '' ? <TypingAnimation />
        : <Markdown remarkPlugins={[remarkGfm]}>{msg}</Markdown> }

    </MarkdownContainer>
  </Grid2>
);
