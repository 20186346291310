/**
 * @generated SignedSource<<0fda0b6e4586c60d59c17c4a4a215418>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrderForPurchaseMutation$variables = {
  languageId: number;
  packageId: number;
  price: number;
};
export type CreateOrderForPurchaseMutation$data = {
  readonly orders_createpurchaseorder: {
    readonly data: {
      readonly invoiceNumber: string;
      readonly paypalPaymentId: string;
      readonly uuid: string;
    } | null;
    readonly success: boolean;
  } | null;
};
export type CreateOrderForPurchaseMutation = {
  response: CreateOrderForPurchaseMutation$data;
  variables: CreateOrderForPurchaseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languageId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "packageId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "price"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "languageId",
        "variableName": "languageId"
      },
      {
        "kind": "Variable",
        "name": "packageId",
        "variableName": "packageId"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      }
    ],
    "concreteType": "OrdersCreatePurchaseOrderOutput",
    "kind": "LinkedField",
    "name": "orders_createpurchaseorder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersCreatePurchaseOrder",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paypalPaymentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrderForPurchaseMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrderForPurchaseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd81f3f59a33422cee8160d2c79f3572",
    "id": null,
    "metadata": {},
    "name": "CreateOrderForPurchaseMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrderForPurchaseMutation(\n  $languageId: Int!\n  $packageId: Int!\n  $price: Float!\n) {\n  orders_createpurchaseorder(languageId: $languageId, packageId: $packageId, price: $price) {\n    success\n    data {\n      paypalPaymentId\n      invoiceNumber\n      uuid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba17c9239bcd832f8861c8ce479e27fd";

export default node;
