import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useFragment } from 'react-relay';
import { NavLink } from 'react-router-dom';
import {
  CardStandard, Ty, Grid2Ct, Grid2, Link, IcSvgList,
} from '@languageconvo/wcl';
import { GetGroupClassroomFragment } from '../../../../../layouts/dashboard/relay/GetGroupClassroom';
import { HowGroupClassWorkSlider } from './HowGroupClassWorkSlider';
import { groupClsOverviewDataNotsubbed } from './groupClsOverviewDataNotsubbed';
import { sitedata } from '../../../../../utils/sitedata';

interface Props {
  groupClassroomSubscriptionFragmentRef: any
  modalStateInfo: boolean,
  setModalStateInfo: Dispatch<SetStateAction<boolean>>
}

// TODO: add a comment here -- what's this component for/what does it do
export const HowGroupClassWork = ({
  groupClassroomSubscriptionFragmentRef,
  modalStateInfo,
  setModalStateInfo
}: Props) => {
  // reading the data using groupClassroomSubscriptionFragmentRef and 
  // groupClassroomSubscriptionFragment
  const groupClassroomSubscriptionData = useFragment(
    GetGroupClassroomFragment,
    groupClassroomSubscriptionFragmentRef,
  );

  // TODO: this comment is wrong. groupcl doesn't have anything to do with "advanced scheduling",
  //  it just simply tells us if the user is subscribed to group classes or not
  // groupClassroomSubscriptionData.groupcl indicates whether the user has advanced 
  // scheduling access.
  //   groupcl has possibly two values..
  //   - 0 (default), user does not have advanced scheduling access(they've not 
  //       subscribed groupLesson)
  //   - 1, user does have advanced scheduling access(they've subscribed to groupLesson)

  useEffect(() => {
    if (groupClassroomSubscriptionData !== null || groupClassroomSubscriptionData === undefined) {
      if (groupClassroomSubscriptionData.groupcl === 0) {
        setModalStateInfo(true);
      } else {
        // do nothing
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupClassroomSubscriptionData]);

  // TODO: why, not what
  // checking if groupClassroomSubscriptionData is either null or undefined.
  // if so, return an empty div to avoid rendering errors.
  if (groupClassroomSubscriptionData === null || groupClassroomSubscriptionData === undefined) {
    return (
      <div />
    );
  }

  if (!Object.hasOwn(groupClassroomSubscriptionData, 'groupcl')) {
    return (
      <div />
    );
  }

  return (
    <div>
      {modalStateInfo && (
        <HowGroupClassWorkSlider groupClsOverviewData={groupClsOverviewDataNotsubbed} />
      )}

      {/* if the user is not subscribed to group classes, show them a free trial option */}
      {groupClassroomSubscriptionData.groupcl === 0 ? (<NotsubbedFreeTrial />) : (<> </>)}
    </div>
  );
};

export const NotsubbedFreeTrial = () => {
  // link to purchase page
  const clickableTypeObjPurchpg = {
    to: sitedata.url.app.purchaseHomeDt.pathFull,
    component: NavLink,
  };

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <CardStandard
          titleText="Free Trial"
          titleIcon={IcSvgList.star1}
          color="accentGreen1"
          cp={{ sx: { width: '100%' } }}
          clickableLink1={{ ...clickableTypeObjPurchpg }}
        >
          <Link
            to={sitedata.url.app.purchaseHomeDt.pathFull}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12} display="flex">
                <Ty removeMb>
                  Try unlimited bite-sized conversational classes, free!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};
