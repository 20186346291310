/**
 * @generated SignedSource<<15895fa6491c3a94f0781f1a7b153e14>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomepageloadAcctcreateFragment$data = {
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly random_id_public: string;
  readonly uuid: any;
  readonly " $fragmentType": "HomepageloadAcctcreateFragment";
};
export type HomepageloadAcctcreateFragment$key = {
  readonly " $data"?: HomepageloadAcctcreateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomepageloadAcctcreateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomepageloadAcctcreateFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "random_id_public",
        "storageKey": null
      },
      "action": "THROW",
      "path": "random_id_public"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      "action": "THROW",
      "path": "email"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      "action": "THROW",
      "path": "first_name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      "action": "THROW",
      "path": "last_name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      "action": "THROW",
      "path": "uuid"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "fce840bec5ed66ee4fd6d5f35902fe55";

export default node;
