import React, { useState } from 'react';
import {
  Swiper, Grid2, SwiperSlide, Grid2Ct, Ty, Ic, IcSvgList, CardStandard, Dialog, Box,
} from '@languageconvo/wcl';
import {
  GroupOverViewClsSlideMeasurements,
  SwiperContainerHowGroupClsWork,
  MuxPlayerCustom,
} from './HowGroupClassWork.style';

interface Props {
  groupClsOverviewData: any
}

// Helper function to group array into chunks of 4
const chunkArray = (arr: any[], chunkSize: number) => {
  const results = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    results.push(arr.slice(i, i + chunkSize));
  }
  return results;
};

// slider component. we will show slides how group lesson works
export const HowGroupClassWorkSlider = ({ groupClsOverviewData }: Props) => {
  // group the data into arrays of 4
  const groupedData = chunkArray(groupClsOverviewData, 4);

  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText="How Bite-Sized Conversational Classes Work"
          titleIcon={IcSvgList.group1}
          color="accentOrange1"
          titleIconRight={0}
          cp={{ sx: { width: '100%' } }}
        >
          <Grid2Ct>
            <Box sx={{ minHeight: GroupOverViewClsSlideMeasurements.heightPlusPadding, width: '100%' }}>
              <Grid2 xs={12}>
                <SwiperContainerHowGroupClsWork>
                  <Swiper
                    ourcustomcolor="accentPurple1"
                    ourcustomwidth="100%"
                    slidesPerView="auto"
                    freeMode={{
                      enabled: true,
                      momentumBounce: false,
                    }}
                    pagination={{ clickable: true }}
                    spaceBetween="15"
                  >
                    {/* how it works video */}
                    {/* class pgGroupsched-howtovid is very important; we use it to apply
                    custom css for smaller width */}
                    <SwiperSlide class="pgGroupsched-howtovid">
                      {/* this creates border radius on the video */}
                      {/* TODO: use radius from our theme */}
                      <Box sx={{ display: 'flex', overflow: 'hidden', borderRadius: '11px' }}>
                        <MuxPlayerCustom
                          playbackId="8J802xh02Ao7SdIhp00ZSLZ02GKT4n3Lz9R00VtlSfWQydi8"
                          theme="minimal"
                          thumbnailTime={0}
                        />
                      </Box>
                    </SwiperSlide>

                    {/* Loop through each group of 4 items */}
                    {groupedData.map((dataGroup: any[]) => (
                      <SwiperSlide>
                        <SingleCard dataGroup={dataGroup} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </SwiperContainerHowGroupClsWork>
              </Grid2>
            </Box>
          </Grid2Ct>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// Display cards to give overview related to GroupLesson
const SingleCard = ({ dataGroup }: { dataGroup: any[] }) => {
  // state to hold the info related to selected question which we will show in dialog
  const [selectedQuestion, setSelectedQuestion] = useState<any | null>(null);
  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState(false);

  const handleQuestionClick = (data: any) => {
    setSelectedQuestion(data);
    setModalState(true);
  };

  const handleModalClose = () => {
    setModalState(false);
  };

  return (
    <>
      <Grid2Ct>
        {/* TODO: if you copy/paste code, review it carefully. the comments below don't
        make any sense, they look like comments from some other code */}
        {/* date, time range, teacher name */}
        <Grid2 xs={12}>
          {/* noWrap is important. if any individual line is too long it will NOT wrap to
          another line. if it did, the "enter class" button might be pushed down out of view */}
          {dataGroup.map((data: any) => (
            <Box onClick={() => handleQuestionClick(data)} sx={{ cursor: 'pointer', mt: 1, mb: 1 }}>
              <Grid2Ct>
                <Grid2 xs={10}>
                  {data.title}
                </Grid2>
                <Grid2 xs={2} textAlign="right">
                  <Ic
                    iconName="angle-right"
                    iconStyle={data.iconStyle}
                    color="accentPurple1"
                  />
                </Grid2>
              </Grid2Ct>
            </Box>
          ))}
        </Grid2>
      </Grid2Ct>

      {selectedQuestion && (
        <Dialog
          isOpen={modalState}
          onClose={handleModalClose}
          width="md"
          color={selectedQuestion.iconColor}
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              {/* Display the selected question title */}
              <Ty v="h2New">{selectedQuestion.title}</Ty>

              {/* Display the selected question text */}
              <Ty cp={{ sx: { mt: 2 } }}>
                {selectedQuestion.text}
              </Ty>
            </Grid2>
          </Grid2Ct>
        </Dialog>
      )}
    </>
  );
};
