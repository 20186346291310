import React, { Dispatch, SetStateAction, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useSubscription } from 'react-relay';
import { GetUserCurrentPlanSubscription } from '../relay/GetUserCurrentPlan';
import { GetUserCurrentPlanSubscription as GetUserCurrentPlanSubscriptionType } from '../relay/__generated__/GetUserCurrentPlanSubscription.graphql';

interface Props {
  setCurrentPlanFragmentRef: Dispatch<SetStateAction<any>>;
}

/** here we are creating a graphql subscription to the `subs` table, particularly the
 * current_plan, current_plan_status of that table. and `order` columns from subs_mainplans 
 * these column tells us whether or not the user is subscribed. 
 */
export const GetUserCurrentPlanSubsTableSubscription = ({ setCurrentPlanFragmentRef }:
   Props) => {
  // #region for `GetUserCurrentPlanInfo` subscription 

  // subsctiption configurations for GetUserCurrentPlanInfo
  const GetUserCurrentPlan: any = useMemo(() => ({
    // Define a subscription object
    subscription: GetUserCurrentPlanSubscription,
    // a callback that is executed when a subscription payload is received
    onNext: (res: any) => {
      setCurrentPlanFragmentRef(res.subs_connection.edges[0].node);
    },

    // a callback that is executed when the subscription errors.
    onError: (err: any) => {
      // eslint-disable-next-line no-console
      console.log('err in User Current Plan Subs table Subscription', err);
      Sentry.captureException(err);
    },

    // a callback that is executed when the server ends the subscription
    onCompleted: () => {
      Sentry.captureMessage('UIS Get User Current Plan Subs table Subsription completed!');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // executing the subscription for checking if the user subscribed group classroom or not
  useSubscription<GetUserCurrentPlanSubscriptionType>(GetUserCurrentPlan);

  // #endregion

  return (
    <div />
  );
};
