import React, { useState, useLayoutEffect } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import {
  Grid2, Ty, Box, Button,
} from '@languageconvo/wcl';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { DateTime } from 'luxon';
import { sitedata } from '../../../../../../utils/sitedata';
import { TrialLessonInfoFragment, TrialLessonInfoQuery } from './StepsToSchedule/StepsToSchedule4Step3/relay/TrialLessonInfo';

export const UpComingLessonInfo = () => {
  // #region for getting currentTime

  // here we are storing current time in state
  const [currentTmsp, setCurrentTmsp] = useState(0);

  useLayoutEffect(() => {
  // the current unix timestamp, used by relay query to get upcoming/ongoing lessons
    setCurrentTmsp(Math.floor(DateTime.now().toSeconds()));
  }, []);

  // #endregion

  // We must *always* go to the network to get upcoming lessons. The reason being, our
  // scheduling pages are in our legacy app (iframes), so we do not get scheduled lesson
  // data in relay
  const response: any = useLazyLoadQuery(
    TrialLessonInfoQuery,
    { currentTs: currentTmsp },
    {
      fetchPolicy: 'network-only',
      // fetchKey is VERY IMPORTANT -- it must be set to a unique value to ensure we always
      // go to the network to get fresh data
      fetchKey: currentTmsp,
    },
  );

  // TODO: john will decide what to show here.
  if (response.appointments_connection.edges.length === 0) {
    return (
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Ty align="center" removeMb>
            No Lesson Found!
          </Ty>
        </Box>
      </Grid2>
    );
  }

  const { node } = response.appointments_connection.edges[0];
  // reading lessonDetail data
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lessonDetails = useFragment(TrialLessonInfoFragment, node);
  const { starts_at, ends_at, id_random } = lessonDetails;

  // link to the classroom
  const lsnLink = `${sitedata.url.app.privateClassroom}${id_random}`;
  // TODO: the "to" here doesn't do anything. we needed target="_blank", and this was
  // the only easy way to get it. it's not correct though, something weird is going on.
  // https://mui.com/material-ui/guides/routing/#react-router-examples
  const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => <RouterLink ref={ref} to={lsnLink} {...props} role={undefined} />,
  );
  return (
    <>
      {/* date and time */}
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Ty align="center" removeMb>
            {DateTime.fromSeconds(starts_at).toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' })}
            <br />
            {DateTime.fromSeconds(starts_at).toLocaleString({ timeStyle: 'short' }) } - {DateTime.fromSeconds(ends_at).toLocaleString({ timeStyle: 'short' }) }
          </Ty>
        </Box>
      </Grid2>

      {/* button */}
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            color="accentGreen1"
            to={lsnLink}
            component={LinkBehavior}
            size="large"
          >
            Enter Lesson!
          </Button>
        </Box>
      </Grid2>
    </>
  );
};
