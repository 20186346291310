import * as Sentry from '@sentry/react';
import { update, trackEvent, shutdown } from '@intercom/messenger-js-sdk';
import { EventdtAccountCreated, EventdtPurchase } from './eventmanager';

// IMPORTANT: DO NOT CHANGE THESE
// IMPORTANT: DO NOT CHANGE THESE (unless you REALLY know what you're doing)
// the names/titles of each event. note that some destinations require special naming.
// these are very important in each tool, do NOT change them unless you know you really
// need to
enum EventTitles {
  createaccount = 'Account Created',
  purchase = 'Order Completed',
  signout = 'Signed Out',
}

// new user account created
export const accountcreatedIn = (dt: EventdtAccountCreated) => {
  try {
    const eventname = EventTitles.createaccount;
    const theName = `${dt.firstName} ${dt.lastName}`;

    // note that on app init, we *only* pass user id to intercom; we do not pass email
    // address or any other identifying info. so when they first create an account on our site
    // we wont have any of that info in intercom *unil* our reverse etl process has run. so,
    // here we include their name and email address so that we get this info in intercom right
    // away when the user has first created an account
    update({
      name: theName,
      email: dt.email,
      user_id: dt.userid,
    });

    // this actually creates the event in Intercom
    trackEvent(
      eventname,
    );

    // eslint-disable-next-line no-console
    console.log('in: account created');
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

// purchased private lessons
export const purchaseIn = (dt: EventdtPurchase) => {
  try {
    trackEvent(
      EventTitles.purchase,
      {
        value: dt.value,
        currency: 'USD',
        eventid: dt.eventuuid,
      }
    );

    // eslint-disable-next-line no-console
    console.log('in: purchase');
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

// log out, step 1. this sends an actual track event so we can see it in the user's event history
export const logoutInStep1 = () => {
  try {
    trackEvent(EventTitles.signout);
  } catch (e) {
    Sentry.captureException(e);
  }
};

// log out, step 2. this removes the user's intercom data from their browser (very important
// to do, so that if they log in as a *different* user their data won't be mixed)
export const logoutInStep2 = () => {
  try {
    shutdown();
  } catch (e) {
    Sentry.captureException(e);
  }
};
