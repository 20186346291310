import { graphql } from 'babel-plugin-relay/macro';

export const GetAppLevelData = graphql`
  query GetAppLevelDataQuery {
    ...GetLanguageFromAppointments
    ...GetLanguageFromOrders
    ...GetLanguageFromUsers
    ...GetUserData
    ...ExtractPreferredLanguageFromStore
    ...GetAppLevelDataAllteachersFragment
    # Get the language the user is currently learning
    # This greatly reduces the number of api calls the app has to make when it loads; lang_learning
    # is used by both the desktop and mobile navbars, as well as many pages; if we dont get this
    # here, then *all* of those components fire off queries to get this data
    users_connection {
      edges {
        node {
          ...GetAppLevelDataLanglearnFragment
        }
      }
    }

    # get the data needed for our events manager, which loads in the Dashboard component
    users_connection {
      edges {
        node {
          ...EventsDataFragment
        }
      }
    }
  }  
`;

// lang_learning value, the language the user is learning
export const GetAppLevelDataLanglearnFragment = graphql`
  fragment GetAppLevelDataLanglearnFragment on users {
    lang_learning
  }
`;

// TODO: STOP, READ THIS!!!
// In the fragment below, GetAppLevelDataAllteachersFragment, the where clause is used in
// another location. IF YOU EDIT THE WHERE CLAUSE BELOW, you will also need to go into
// FavoriteTeacherIframeEventFromLegacy, find this line:
// const root = store.get('client:root:resources_connection(
//    where:{"tutor_visibility":{"_eq":"1"},"working_status":{"_is_null":true}})');
//
// and update that to have the same where. Otherwise the functionality of adding a teacher to
// a student's favorites, when the student schedules a lesson via legacy app scheduling, breaks

// here we are getting *all* active teachers. we do this at the app root so that many pages/
// components load really fast. for most components/pages, we'll already have all the data
// we need about each teacher
export const GetAppLevelDataAllteachersFragment = graphql`
  fragment GetAppLevelDataAllteachersFragment on query_root {
   resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: "1"}}) {
      edges {
        node {
          id
          pk
          uuid
          gets_new_students
          wants_new_students
          provides_free_trial
          title
          stuprof_aboutme
          stuprof_city
          relayFavorited
          instructor_languages {
            language_id
          }
          user_favteachers {
            favorite
          }
          stuprof_country {
            common_name
          }
          resources_files_profileimgs(limit: 1, order_by: {sort_order: asc}, where: {image_approved: {_eq: "1"}, archived: {_eq: "0"}, sort_order: {_gt: -1}}) {
            fuploads {
              file_path
              file_cdn {
                base_url
              }
            }
          }
        }
      }
    }  
  }
`;
