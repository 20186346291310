import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

/* Why was this file created when we already have posthog.ts?
    See the note at the top of eventmanagerNonhook file. The logout events cannot be called
    from a custom hook. Posthog says in their docs to *not* import posthog directly
    like we are doing here! They say it can cause problems. So, we created this file to
    *only* do the logout using this anti-patern of importing posthog directly.
*/

// IMPORTANT: DO NOT CHANGE THESE
// IMPORTANT: DO NOT CHANGE THESE (unless you REALLY know what you're doing)
// the names/titles of each event. note that some destinations require special naming.
// these are very important in each tool, do NOT change them unless you know you really
// need to
enum EventTitles {
  signout = 'signed_out',
}

// log out, step 1. this sends an actual track event so we can see the "signed_out" event
// it in the user's posthog event history
export const logoutPgStep1 = () => {
  try {
    posthog.capture(EventTitles.signout);
  } catch (e) {
    Sentry.captureException(e);
  }
};

// log out, step 2. this removes the user's posthog data from their browser (very important
// to do, so that if they log in as a *different* user their data won't be mixed)
export const logoutPgStep2 = () => {
  try {
    posthog.reset();
  } catch (e) {
    Sentry.captureException(e);
  }
};
